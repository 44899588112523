@import 'assets/stylesheets/variables.scss';
.survey-feedback-questions-container {
  background: $bodyBackgroundColor;
  margin-top: -18px; // min-height: calc(100vh);
  .survey-feedback-header {
    font-weight: 100;
    padding-top: 20px;
    @media screen and (max-width: 572px) {
      padding-top: 0px;
    }
    .survey-feedback-title {
      font-size: 26px;
      @media screen and (max-width: 572px) {
        font-size: 20px;
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
    .back-navigator {
      color: $primaryThemeColor;
      font-weight: 300;
      padding-left: 30px;
      margin-top: 5px;
      @media screen and (max-width: 572px) {
        padding-left: 10px;
        padding-top: 10px;
      }
      .back-label {
        font-size: 16px;
        margin-top: 1px;
        margin-left: 0;
        @media screen and (max-width: 572px) {
          font-size: 14px;
        }
      }
      .icon:before {
        top: 3px;
        left: 0px;
        @media screen and (max-width: 572px) {
          font-size: 20px;
        }
      }
    }
  }
  .survey-feedback-questions {
    width: 70%;
    margin: 0 auto;
    min-height: calc(100vh - 130px);
    background: white;
    @media screen and (max-width: 572px) {
      width: 100%;
      margin: 0 auto;
    }
    @media screen and (min-width: 573px) and (max-width: 767px) {
      width: 80%;
      margin: 0 auto;
    }
    .question {
      margin: 20px 0px -20px 0;
      border-bottom: 1px solid $borderColor;
      background: white;
      padding: 20px;
      @media screen and (max-width: 572px) {
        padding: 15px 20px;
        margin: 0;
      }
      &:first-child {
        padding-top: 15px;
      }
      &:last-child {
        border-bottom: none;
      }
      .survey-feedback-error-no-user-response {
        color: $red;
        font-weight: 100;
        margin-top: 10px;
      }
      .survey-feedback-error-no-additional-user-response {
        color: $red;
        font-weight: 100;
        margin-left: -5px;
        margin-top: 5px;
      }
      .question-header {
        //height: 35px;
        margin: 0 -10px 15px -10px;
        background: $bodyBackgroundColor;
        padding: 10px;
      }
      .survey-feedback-question-text {
        font-size: 16px;
        font-weight: 500;
        @media screen and (max-width: 572px) {
          font-size: 14px;
        }
      }
      .survey-feedback-answer-text {
        font-size: 14px;
        font-weight: 100;
        margin-top: 5px;
        @media screen and (max-width: 572px) {
          font-size: 12px;
        }
      }
      .possible-answers-container {
        margin-top: 20px;
        margin-left: 10px;
        @media screen and (max-width: 572px) {
          margin-top: 15px;
        }
        .survey-feedback-possible-answers {
          display: block;
          color: $primaryThemeColor;
          .survey-feedback-possible-answers--answer {
            display: flex;
            align-items: center;
            @media screen and (max-width: 572px) {
              padding-bottom: 10px;
            }
          }
          .survery-feedback-answer-label {
            color: black;
            font-weight: 100;
            margin-left: 14px;
            font-size: 14px;
          } // pain answers icon
          .icon {
            &.default {
              color: #46902e;
            }
            &.zero {
              color: #e97b55;
            }
            &.one {
              color: #f69a4a;
            }
            &.two {
              color: #e0be40;
            }
            &.three {
              color: #a6cd45;
            }
            &.four {
              color: #46902e;
            }
          }
          .radio {
            position: relative;
            display: block;
            margin-top: 9px;
            margin-bottom: 10px;
            > input {
              visibility: hidden;
              position: absolute;
            }
          }
        }
        .clear-both {
          clear: both;
        }
      }
      .survey-feedback-possible-answer-textarea {
        margin-top: 15px;
        width: 100%;
        height: 90px;
        resize: none;
      }
      .survey-feedback-additional-response {
        margin-left: 32px;
        textarea {
          width: 165px;
          height: 40px;
          resize: none;
        }
      }
    }
  }
  .survey-feedback-action-button-container {
    padding: 20px;
    background: white;
    .survey-feedback-action-button {
      float: right;
    }
  }
}

.survey-feedback-container .modal-content {
  height: 85vh;
  width: 100%;
  .btn-primary {
    position: absolute;
    bottom: 25px;
    right: 20px;
  }
  iframe {
    height: 70vh;
    pointer-events: none;
  }

  .modal-title {
    font-size: 16px !important;
  }
}

.select-site-banner-survey {
  margin: 24px 15px 21px 15px;
  position: relative;
  top: 24px;
  background-color: #ebf1fb;
}

.site-thanks-msg {
  padding: 15px 35px 15px 15px;
}

.site-select-survey-button {
  position: relative;
  margin-left: auto;
  width: auto;
  justify-self: end;
  align-self: end;
  /* Absolute Blue */

  background: $primaryThemeColor;
  border: 1.62667px solid $primaryThemeColor;
  box-sizing: border-box;
  border-radius: 48.8px;
  margin-right: auto;
}

.site-select-survey-button-text {
  font-size: 16px;
  color: white;
  padding: 10px 5px;
}

.site-select-survey-button-container {
  display: grid;
  justify-content: center;
  margin-bottom: 20px;
}

.select-site-survey-footer-msg {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  margin-top: 10px;
}
