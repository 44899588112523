.passive-mode-footer {
  text-align: center;
  border-top: 1px solid #bbd0e2;
  background: white;
  position: fixed;
  width: 100vw;
  bottom: 0px;
  padding-bottom: 15px;
  font-size: 14px;
  @media screen and (max-width: 572px) {
    font-size: 12px;
  }
  @media screen and (min-width: 573px) and (max-width: 991px) {
    font-size: 14px;
  }
  .title {
    font-weight: 300;
    margin-top: 10px;
    @media screen and (max-width: 572px) {
      margin: 5px 0;
    }
  }
  .subtitle {
    margin: 10px 0;
    font-weight: 100;
    @media screen and (max-width: 572px) {
      margin: 5px 0;
    }
  }
}
