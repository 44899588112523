@import 'assets/stylesheets/variables.scss';
.attachments-container {
  font-size: 14px;
  line-height: 25px;
  margin: 15px 0px 5px 0px;
  .attachments-count {
    font-weight: 300;
  }
}

// .attachment-seperator {
//   border-right: 1px solid #c9c9c9;
//   margin: 10px 12px;
//   &:last-child {
//     border-right: 0;
//   }
// }
.attachment-media {
  height: 50px;
}
