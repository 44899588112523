@import 'assets/stylesheets/variables.scss';
.border-type-email {
  width: -100px;
  padding: 0px 0px 0px 0px;
  margin-bottom: 0%;
  border-radius: 5px;
  border: 2px solid $primaryThemeColor;

  input[type='text'] {
    border: 0px;
    outline: none;
  }
  @media screen and (max-width: 570px) {
    width: 90%;
    margin: 20px;
  }
}
.label_style_email {
  display: flex;
  justify-content: left;
  color: $primaryThemeColor;
  font-size: 12px;
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  margin-left: 4px;
  @media screen and (max-width: 570px) {
    display: flex;
    justify-content: left;
  }
}
input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
}
.input-text-email {
  font-family: 'ProximaNova';
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 0px;
}

.input-control_before {
  height: 25px;
  border-color: transparent;
  align-items: center;
  font-size: auto;
  color: #72788a;
  @media screen and (max-width: 570px) {
    margin-top: 1px;
    width: 90%;
    left: 0px;
  }
}

.input_control_after {
  align-items: center;
  height: 40px;
  font-size: auto;
  color: #72788a;
  @media screen and (max-width: 570px) {
    margin-top: 5px;
    width: 90%;
  }
}

.has-error-self-signup {
  width: -100px;
  padding: 0px 0px 0px 0px;
  margin-bottom: 0%;
  border-radius: 5px;
  border: 2px solid red;

  input[type='text'] {
    border: 0px;
    outline: none;
  }
}
