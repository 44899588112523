.consent-form {
  min-height: 150px;
  max-height: 400px;
  overflow-y: auto;
  font-size: 13px;
}
.consent-form-text {
  margin-top: -20px;
}
.consent-form-signed-name {
  margin-top: 30px;
}

.consent-form-buttons {
  margin-bottom: 30px;
}
