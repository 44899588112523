@import 'assets/stylesheets/variables.scss';

.pagination-container {
  display: flex;
  justify-content: flex-end;

  .total-results-container {
    .results-count {
      line-height: 30px;
    }
  }
  .pagination-body-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .go-to-page {
      display: flex;
      margin: 0 5px 0 10px;
    }
    .prev-page {
      margin-left: 5px;
      border-color: $primaryThemeColor !important;
      color: $primaryThemeColor !important;

      &.disabled {
        border-color: $borderGreyColor !important;
        color: $borderGreyColor !important;
      }
    }
    .next-page {
      margin-left: 5px;
      border-color: $primaryThemeColor !important;
      color: $primaryThemeColor !important;
      &.disabled {
        border-color: $borderGreyColor !important;
        color: $borderGreyColor !important;
      }
    }
    .page-nav-icon {
      border: 1px solid $borderGreyColor;
      display: inline-block;
      line-height: 30px;
      width: 28px;
      height: 30px;
      border-radius: 5px;
      cursor: pointer;
      color: black;

      &:hover {
        border: 1px solid $primaryThemeColor;
        color: $primaryThemeColor;
      }

      .icon {
        position: relative;
        top: 0px;
        left: -4px;

        &:before {
          font-size: 18px;
        }
      }
      &.disabled,
      &.disabled:hover {
        cursor: default;
        border: 1px solid $borderGreyColor;
        color: $borderGreyColor;
      }
    }
  }
  .form-group {
    display: inline-block;
    margin-bottom: 0;

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input {
      width: 35px;
      height: 32px;
      border: 1px solid lightgray;
      border-radius: 5px 0px 0px 5px;
    }
  }
  .btn-primary {
    height: 32px;
    line-height: 20px;
    padding: 5px;
    font-weight: 300;
    font-size: 14px;
    border: 1px solid $primaryThemeColor;
    border-radius: 0px 5px 5px 0px;
  }
}
