@import 'assets/stylesheets/variables.scss';

//@font-face {
//  font-family: "Proxima Nova";
//  src: url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot');
//  src: url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'),
//  url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.woff') format('woff'),
//  url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.ttf') format('truetype'),
//  url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.svg#proxima_nova_rgregular') format('svg');
//  font-weight: normal;
//  font-style: normal;
//}
.success-text-title {
  font-size: 24px;
  display: inline-block;
}
.inupt-text {
  font-family: 'ProximaNova';
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 3px;
}
.input-box {
  margin-top: 16px;
}

.patient-register-container {
  background: -o-linear-gradient(top, #0c6ef8);
  background: linear-gradient(bottom, #0c6ef8);
  color: $black;

  .sucess-view-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    .image-sucess-container {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      max-height: 500px;
      min-height: 400px;
      font: inherit;
      width: 70%;
      min-width: 980px;
      vertical-align: baseline;
      img {
        width: 100%;
        max-width: 100%;
        height: 100%;
      }
    }
  }

  .btn-primary {
    //position: absolute;
    //width: 288.46px;
    //height: 57.69px;
    //left: 43.27px;
    //top: 658.23px;
    background: $primaryThemeColor;
    //border-radius: 3.84615px;

    //top: 50%;
    //left: 50%;
    //-ms-transform: translate(-50%, -50%);
    //transform: translate(-50%, -50%);

    //background-color: #E5E5E5;
    //height: 35px;
    //border-radius: 20px;
    //padding: 0px 20px;
    //font-weight: 300;
    //font-size: 16px;
    @media screen and (max-width: 768px) {
      font-size: 14px;
      height: 30px;
    }
    &:hover {
      background-color: $primaryThemeColor;
      border: 1px solid $primaryThemeColor;
      .icon:before {
        color: white;
      }
    }
  }

  .btn-primary:active,
  .btn-primary:focus {
    background-color: $primaryThemeColor !important;
    &.red {
      background-color: $red !important;
      border: 1px solid $red;
    }
    color: #ffffff !important;
    border: 1px solid $primaryThemeColor;
  }

  .btn.btn-primary[disabled],
  .btn.btn-primary[disabled]:hover {
    background-color: $lightGray !important;
    border: 1px solid $lightGray !important;
  }

  .input-container {
    text-align: left;
  }
  .welcome-section {
    color: $white;
    margin-top: 50px;
    @media screen and (max-width: 768px) {
      margin-top: 0px;
    }
  }
  .copyright-container {
    margin: 10px 15px 20px;
    padding: 30px 15px;
    position: relative;
    color: $white;
    font-size: 12px;
    font-weight: 100;
    text-align: center;
  }

  .secondary-logo {
    max-height: 500px;
    max-width: 500px;
    width: auto;
    height: auto;
    //left: -71.720703125px;
    //top: -120.0009765625px;
  }

  .ins-logo {
    // //position: absolute;
    // width: 192.31px;
    // height: 70.2px;
    // left: 91.34px;
    // top: 61.37px;
  }

  @media screen and (min-width: 800px) and (max-width: 1280px) {
    .secondary-logo {
      width: 375px;
      height: 375px;
    }
  }

  @media screen and (min-width: 200px) and (max-width: 799px) {
    .secondary-logo {
      height: 375px;
      width: auto;
    }
  }

  .inline-italic-txt {
    font-style: italic;
  }

  .steps-container {
    padding: 30px 15px;
    background-color: $white;
    position: relative;
    color: $black;
    font-size: 16px;
    font-weight: 100;

    @media screen and (min-width: 200px) and (max-width: 799px) {
      margin: 40px 5% 20px;
    }

    @media screen and (min-width: 800px) and (max-width: 1280px) {
      margin: 40px 10% 20px;
    }

    .phone-number {
      font-size: 20px;
      font-weight: 100%;
    }

    .step-number {
      position: absolute;
      right: 10px;
      top: 17px;
      color: $themeLightGrayColor;
    }
    .prev-link {
      position: absolute;
      left: 10px;
      top: 10px;
      color: $themeLightGrayColor;
      .icon::before {
        top: 6px;
      }
    }
    .step-section {
      margin-top: 0px;
      .names {
        margin: 40px 40% 20px 50px;
        //position: absolute;
        //width: 288.46px;
        //height: 48.08px;
        //left: 43.27px;
        //top: 264.29px;
        background: #ffffff;
        border: 1px #72788a;
        box-sizing: border-box;
        border-radius: 3.84615px;
      }
    }
    h2 {
      margin: 0px 40% 20px;
      //width: 91.04px;
      //height: 31.56px;
      //left: 141.98px;
      //top: 164.19px;
      font-style: normal;
      font-weight: bold;
      font-size: 32.6923px;
      line-height: 38px;
      color: $insBlack;
    }
    p {
      margin: 0px 35% 20px;
      width: 205.08px;
      height: 27.77px;
      left: 84.96px;
      top: 204.17px;
      font-style: normal;
      font-weight: bold;
      font-size: 26.9231px;
      line-height: 38px;
      color: $insBlack;
    }
    .input-label-without-value {
      font-size: 20px;
      top: 0;
    }

    .single-select {
      &__placeholder,
      &__single-value {
        width: 100%;
        font-size: 20px !important;
        text-align: left;
      }
      &__control {
        border-bottom: 1px solid $black !important;
      }
      &__option {
        font-size: 18px !important;
        text-align: left;
      }
      &__indicator {
        color: #000000 !important;
        opacity: 0.6;
      }
    }

    .has-error {
      .single-select__control {
        border-color: #fb647c !important;
      }
    }

    .form-group-select {
      position: relative;
      .icon {
        position: absolute;
        top: 6px;
        right: 0px;
        color: $black;
      }
    }
    .input-label-is-focused {
      color: $white !important;
    }
    .date-picker-field .form-control {
      background-color: inherit;
      height: 40px;
    }
    .info-text {
      font-size: 12px;
      font-weight: 300;
    }
    .date-field {
      border-bottom-color: $themeOrangeColor !important;
    }
    .procedure-date-view {
      .date-picker-field {
        border-bottom: none !important;
        ::-ms-input-placeholder {
          /* Microsoft Edge */
          color: #0c6ef8;
        }
        ::placeholder {
          color: $white;
          opacity: 1; /* Firefox */
        }
        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #0c6ef8;
        }
        .button-container {
          margin-top: 0;
        }
      }
      .btn-input {
        width: 180px;
        background-image: none;
        background: $themeRedColor;
        color: $white;
        border: none;
        border-radius: 20px;
        font-size: 18px !important;
        height: 40px;
      }
    }
  }
  .content-text {
    //position: absolute;
    color: $insBlack;
    //width: 500.15px;
    //height: 70.08px;
    //left: 40.42px;
    //top: 417.31px;
    font-style: normal;
    font-weight: 700;
    font-size: 32.6923px;
    line-height: 38px;
    margin: 0px 30% 0px; //left: 50%;
    //top: 50%;
    //transform: translate(-50%, -50%);
    //text-align: justify;
    //text-justify: inter-word;
    .main-text {
      //width: 400.15px;
      font-size: 26.92px;
      font-weight: 700;
    }
    .intro-text {
      //width: 400.35px;
      //height: 88px;
      //top: 555.87px;

      //font-weight: normal;
      font-size: 16.3462px;
      line-height: 23px;
      font-weight: 400;
    }
    .more-link {
      text-decoration: underline;
      color: $themePurpleColor;
    }

    @media only screen and (max-width: 570px) {
      margin: 0px 5% 0px; //left: 50%;
    }

    @media only screen and (max-width: 900px) and (min-width: 571px) {
      margin: 0px 10% 0px; //left: 50%;
    }

    @media only screen and (max-width: 1300px) and (min-width: 901px) {
      margin: 0px 20% 0px; //left: 50%;
    }
  }
  .primary-logo {
    height: 40px;
  }

  .button-container-new {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    @media screen and (min-width: 570px) {
      margin-top: 40px;
      bottom: auto;
    }
  }

  .button-container {
    button {
      margin-top: 65px;
      //position: absolute;
      //margin: 0px;
      width: 288.46px;
      height: 57.69px;
      //left: 43.27px;
      //top: 719.74px;
      // background: $insBlack;
      border-radius: 3.84615px;
      //top: 419.74px;
      //left: 50%;
    }
    .pain-pump-button-container {
      display: inline-block;
      margin: 20px;
      position: relative;
      .onq-logo {
        position: absolute;
        height: 80px;
        top: -15px;
        left: -8px;
      }
      .ambit-logo {
        position: absolute;
        height: 80px;
        top: -25px;
        right: 5px;
      }
      .onq-button {
        text-align: right;
      }
      .ambit-button {
        text-align: left;
      }
    }
  }
  button {
    min-width: 180px;
  }
  .contact-info {
    font-weight: bold;
    font-size: 12px;
    text-decoration: underline;
  }

  .edit-back-button {
    color: $themeRedColor;
    font-size: 24px;
  }

  .no-padding {
    padding: 0px;
  }

  .dot {
    width: 5px;
    height: 5px;
    background: #000;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    top: -2px;
    margin-right: 10px;
  }

  .check-icon > img {
    height: 15px;
    position: relative;
    top: -1px;
    margin-right: 4px;
  }
}

.signup-conatiner {
  grid-gap: 0 0;
  display: grid;
  gap: 0 0;
  grid-auto-flow: row;
  grid-template-areas: 'hero-container child-container';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;

  .hero-container {
    height: 100%;
    min-width: 710px;
    min-height: 711px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: $absoluteBlue;
    .hero-img-conatiner {
      width: 100%;
      height: 50%;
      display: flex;
      min-height: 363px;
      .back-button-container {
        min-width: 155px;
        .back-btn {
          margin-top: 28px;
          margin-left: 16px;
          font-size: 17px;
          font-weight: 400;
          line-height: 20px;
        }
      }
      .title-text {
        height: 50%;
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: end;
        text-align: left;
        line-height: 35px;
        gap: 17px;
  
        .heading {
          font-weight: 400;
          font-size: 35px;
        }
        .heading2 {
          font-weight: 700;
          font-size: 50px;
          
          @media screen and (max-width: 1540px) {
            font-size: 43px;
          }
        }
      }
      .img-container-inner {
        text-align: center;
        width: calc(100% - 290px);
        .hero-img {
          width: 50%;
          min-width: 405px;
          height: 120%;
          width: auto;
        }
      }
    }
    .hero-containt {
      height: 50%;
      margin-left: 70px;
      color: $white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-weight: 400;
      line-height: 30px;
      font-size: 24px;
      gap: 17px;

      .heading1 {
        font-style: italic;
      }
      .heading2 {
        font-size: 48px;
        line-height: 58.46px;
      }
      .heading3 {
        font-size: 24px;
        font-weight: 400;
        line-height: 38px;
        b {
          font-weight: 700;
        }
      }
    }
    .hero-text {
      position: relative;
      height: 20%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 17px;
      background-color: $insBlack;
      .heading {
        font-weight: 400;
        line-height: 30px;
        font-size: 16px;
        color: $paleBlue;
      }
    }
  }

  .child-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    .progress-bar-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 31px;
      text-align: center;
      margin-bottom: 71px;
      width: 70%;
      margin-left: 15%;
      .eclipse-progress-dot {
        background: $insFormFields;
        width: 16px;
        height: 16px;
        box-shadow: 0px 3px 6px rgba(16, 86, 147, 0.1);
        border-radius: 50%;
      }
      .completed-circle {
        background: $oceanBlue;
      }
    }
    .non-footer-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      order: 0;
      flex: 1 1 100%;
    }

    .input-container {
      width: 343px;
      input {
        border: 1px solid $platinumBorder !important;
        background-color: $white;
        border-radius: 6px;
        line-height: 24px;
        width: 100%;
        height: 56px;
        padding: 14px 16px 14px 16px;
        font-size: 18px;
        font-weight: 400;
      }
      input:focus {
        border: 1px solid $inactive-grey !important;
      }
      .next-btn {
        min-width: 343px;
        height: 56px;
        width: 100%;
        background-color: $absoluteBlue;
        font-size: 20px;
        color: $white;
        line-height: 24px;
        font-weight: 600;
        border-radius: 4px;
        font-family: 'ProximaNova';
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      }
      .input-heading {
        font-weight: 700;
        font-size: 36px;
        line-height: 40px;
        margin: 0;
      }
      .input-text {
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        margin: 0;
      }
      .child-view-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        .btn.btn-primary[disabled] {
          background-color: $insFormFields !important;
          box-shadow: none;
        }
        .signup-input-container {
          position: relative;
          .input-placeholder-show {
            display: inline-block;
            position: absolute;
            font-size: 18px;
            font-weight: 400;
            color: $platinumGrey;
            top: 14px;
            left: 16px;
            background-color: transparent;
          }
          .input-placeholder-hide {
            display: none;
          }
          .input-label-top-show {
            display: inline-block;
            position: absolute;
            top: -8px;
            left: 16px;
            padding: 0 6px;
            background-color: $white;
            font-size: 12px;
            font-weight: 600;
            color: $insBlack;

            .mandatory-field {
              top: 0.1em;
              left: 0.02em;
            }
          }
          .input-label-top-hide {
            display: none;
          }
          .mandatory-field {
            color: $insRed;
            font-size: 18px;
            font-weight: 400;
            top: 0em;
            left: 0.02em;
          }
        }
        .terms-and-conditions {
          width: 300px;
          margin: 0 auto;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          a {
            text-decoration: underline;
            color: $absoluteBlue;
            cursor: pointer;
          }
        }
        .auto-populate-msg {
          color: $absoluteBlue;
          font-size: 18px;
          font-weight: 400;
          text-align: left;
          line-height: 18px;
        }
      }

      .otp-view,
      .treatment-options-view,
      .name-view {
        .input-text {
          padding: 0;
        }
        .mandatory-field {
          top: -0.3em;
          left: 0.02em;
        }
        .auto-populate-msg {
          line-height: 22px;
        }
      }
      .contact-input-container {
        text-align: left;
        input {
          margin: 0;
          width: 100%;
          // margin-top: 20px;
        }
        .form-group {
          margin-bottom: 0;
        }
      }
      .otp-input-container {
        .otp-input-wrapper {
          display: flex;
          gap: 8px;

          .otp-input {
            width: 50px;
            font-size: 32px;
            line-height: 32px;
            text-align: center;
            outline: none;
            padding: 0;
          }
        }
        .resend-code {
          .resend-otp {
            font-size: 18px;
            line-height: 22px;
          }
          .timer {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            margin-top: 16px;

            .timer-timer {
              color: $absoluteBlue;
            }
          }
        }
      }
      .treatment-container-outer {
        .treatment-container-inner {
          display: flex;
          flex-direction: column;
          width: 343px;
          gap: 10px;
          .treatment-option {
            display: flex;
            align-items: center;
            height: 56px;
            text-align: left;
            border-radius: 4px;
            padding: 13px 16px 13px 16px;
            color: $absoluteBlue;
            font-size: 18px;
            font-weight: 600;
            line-height: 30px;
            // border: 1px solid $absoluteBlue;
            box-shadow: 0px 0px 0px 1px $absoluteBlue;
          }
          .selected-option {
            // border: 2px solid $absoluteBlue;
            box-shadow: 0px 0px 0px 2px $absoluteBlue,
              0px 1px 2px 0px $absoluteBlue;
            background-color: $paleBlue;
          }
        }
      }
      .name-view-input-container {
        width: 343px;
        text-align: left;
        .name-input {
        }
        .form-group {
          margin-bottom: 16px;
        }
        .form-group:last-child {
          margin-bottom: 0;
        }
      }
      .password-view {
        .input-heading {
          margin-bottom: 16px;
        }
      }
      .password-view-container {
        text-align: left;
        .password-input-container {
          position: relative;
          .password-show {
            position: absolute;
            right: 14px;
            bottom: 24px;
            color: $primaryThemeColor;
            cursor: pointer;

            &.disabled {
              color: $disabledColor;
              cursor: default;
            }
          }
        }
        .password-instruction-container-outer {
          height: 84px;
          margin: 0 auto;
          margin-top: 16px;
          margin-bottom: 20px;
          .password-instruction-heading {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            width: fit-content;
            margin: 10px auto;
          }
          .password-instruction-container-inner {
            display: flex;
            flex-wrap: wrap;
            width: 234px;
            margin: 19px auto;
            .password-instruction {
              width: 110px;
              text-align: left;
              margin-top: 10px;
              margin-left: 5px;
              .password-instruction-text {
                margin-left: 8px;
                font-size: 14px;
                font-weight: 400;
                font-style: normal;
              }
            }
            .cross {
              padding: 5px;
              background-color: red;
              color: #ffffff;
              border-radius: 50%;
            }
          }
        }
      }
      .has-error {
        .single-select__control {
          border-color: #fb647c !important;
        }
      }
    }
  }
}

.check-icon-hide {
  display: none;
}

.password-show {
  position: absolute;
  right: 14px;
  bottom: 18px;
  color: $primaryThemeColor;
  cursor: pointer;

  &.disabled {
    color: $disabledColor;
    cursor: default;
  }
}

.passwordValidator {
  text-align: left;
  margin-left: 10%;
}

.text-left-align {
  text-align: left;
  margin-left: 20%;
}

.app-listing-logo {
  width: 150px;
  height: 150px;
  margin: 0 15px;
}

.container-rows {
  padding-right: 35%;
  padding-left: 35%;
  margin-right: auto;
  margin-left: auto;

  @media only screen and (max-width: 570px) {
    padding-right: 10%;
    padding-left: 10%;
  }

  @media only screen and (max-width: 900px) and (min-width: 571px) {
    padding-right: 20%;
    padding-left: 20%;
  }

  @media only screen and (max-width: 1300px) and (min-width: 901px) {
    padding-right: 30%;
    padding-left: 30%;
  }
}

.welcome-text-header-1 {
  //font-size: 45px;
  display: block;
  font-size: 2em;
  color: $insBlack;
  margin-top: 1.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  > h2 {
    font-weight: 700;
  }

  @media only screen and (max-width: 570px) {
    display: block;
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
    > h2 {
      font-weight: 700;
    }
  }
}

.welcome-text-header-2 {
  display: block;
  color: $insBlack;

  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  line-height: 30px;
  > h3 {
    font-weight: 700;
  }

  @media only screen and (max-width: 570px) {
    display: block;
    font-size: 1.17em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    > h3 {
      font-weight: 700;
    }
  }
}

input.inupt-text::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: grey;
  font-style: normal;
  font-family: 'ProximaNova';
  font-weight: 400;
}

.input_id {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 400;
  font-size: 16.3462px;
  line-height: 38px;
  /* identical to box height, or 235% */

  /* Form Fields */
}
input {
  margin: 4em;
}
.label_style {
  color: $primaryThemeColor;
  font-size: 12px;
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  margin-left: 4px;
}

.input-control_b4 {
  height: 20px;
  border-color: transparent;
  align-self: center;
  align-items: center;
  font-size: auto;
  color: #72788a;
  @media screen and (max-width: 570px) {
    margin-top: 5px;
  }
}

.input-control {
  align-items: center;
  height: 40px;
  font-size: auto;
  color: #72788a;
  @media screen and (max-width: 570px) {
    margin-top: 5px;
  }
}

.border-type {
  width: -100px;
  padding: 0px 0px 0px 0px;
  margin-bottom: 0%;
  border-radius: 5px;
  border: 2px solid $primaryThemeColor;

  input[type='text'] {
    border: 0px;
    outline: none;
  }
}

.has-error-self-signup {
  width: -100px;
  padding: 0px 0px 0px 0px;
  margin-bottom: 0%;
  border-radius: 5px;
  border: 2px solid red;

  input[type='text'] {
    border: 0px;
    outline: none;
  }
}

.name-control {
  @media screen and (max-width: 570px) {
    margin-bottom: 20px;
  }
}

.radio-control {
  width: 50%;
  height: 20px;
  padding: 100%;
  margin-bottom: -24px;
  margin-left: -30px;
  @media screen and (max-width: 1000px) {
    margin-bottom: -24px;
    margin-left: -10px;
    margin-right: 10px;
  }
}

.diagnosed-options,
.age-options {
  input[type='radio'],
  input[type='checkbox'] {
    width: 20%;
    height: 20px;
    color: black;
    -webkit-appearance: none;
    width: auto;
    padding: 12px;
    transform: scale(2);
    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 10px;
    height: 10px;
    padding: 1px;
    /* background-color only for content */
    background-clip: content-box;
    border: 1px solid $lightGray;
    //background-color: #e7e6e7;
    border-radius: 50%;
  }

  /* appearance for checked radiobutton */
  input[type='radio']:checked {
    padding: 1px;
    background-color: $primaryThemeColor !important;
    border: 1px solid $primaryThemeColor !important;
  }
}

.custom-form-title {
  color: #72788a;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  padding-left: 0px;
  align-self: left;
  margin: 0%;
}

.diagnosed-options {
  text-align: left;
  display: flex;
  margin-bottom: 20px;
}
.diagnosed-options-label {
  align-self: flex-end;
  position: relative;
  left: 12px;
}
.diagnosed-msg {
  margin-top: 20px;
  margin-bottom: 20px;
}

.age-options {
  text-align: left;
  position: relative;
  @media screen and (min-width: 536px) {
    right: 5px;
  }
  .age-options-label {
    position: relative;
    left: 12px;
    // bottom: 5px;
  }
}
.radio-btn-questions {
  margin-left: -6.65%;
  margin-right: -10%;
  input[type='radio'] {
    margin-right: '-6%';
    background-color: 'blue';
  }
}

.btn-radio {
  margin-right: '-6%';
  background-color: 'blue';
}

.mandatory-field {
  color: $insRed;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $insFormFields;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: $insFormFields;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: $insFormFields;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: $insFormFields;
}

::-webkit-input-placeholder:after {
  content: '*';
  color: red;
  vertical-align: top;
  font-size: 10px;
}

:-moz-placeholder:after {
  /* Firefox 18- */
  content: '*';
  color: red;
  vertical-align: top;
  font-size: 10px;
}

::-moz-placeholder:after {
  /* Firefox 19+ */
  content: '*';
  color: red;
  vertical-align: top;
  font-size: 10px;
}

:-ms-input-placeholder:after {
  content: '*';
  color: red;
  vertical-align: top;
  font-size: 10px;
}

input[type='text']:focus {
  outline: none;
}

/* body {
  font-size: calc(0.75em + 1vmin);
} */

@media only screen and (max-width: 320px) {
  body {
    font-size: 2em;
  }
}

/* .image-and-process-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0; */

.background-image-container {
  width: 100%;
  height: 300px;
  background-image: url(../../assets/images/ins_hero_image.png);
  //background-repeat: no-repeat;
  background-size: cover;
  background-position: 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  .header-content {
    top: 10px;
    font-size: 22px;
    line-height: 28px;
    font-weight: 700;
  }

  @media screen and (min-width: 430px) {
    background-image: url(../../assets/images/ipad_hero_image_v2_ins.png);
    height: 270px;
    width: 70%;
    margin: auto;
    .header-content {
      top: 30px;
      font-size: 36px;
      line-height: 43px;
      font-weight: 700;
    }
  }

  @media screen and (max-width: 320px) {
    background-image: url(../../assets/images/ins_hero_image.png);
    height: 200px;

    .header-content {
      top: 20px;
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
    }
  }

  @media screen and (max-width: 875px) {
    height: 450px;
    width: 60%;
  }
  @media screen and (min-width: 1160px) {
    height: 381px;
  }
}

.success-text {
  color: $insBlack;

  text-align: left;
  .success-text-scan-qr-code {
    display: inline-block;
    font-size: 24px;
    font-weight: 700;
    color: $absoluteBlue;
    margin-top: 24px;
    margin-bottom: 16px;
  }

  @media screen and (min-width: 430px) {
    text-align: center;
  }
}

.not-supported-body {
  position: relative;
  width: auto;
  height: auto;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  margin: 40px 0px;
}
.not-supported-body-text {
  position: relative;
  font-style: normal;
  font-weight: 400;
  width: auto;
  height: auto;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 38px;
}
.call-now,
.call-now:hover,
.call-now:focus,
.call-now:active {
  text-decoration: none;
  color: inherit;
}

.resend-code {
  z-index: 99;
  font-weight: 700;
}

.timer {
  // margin-top: 20px;
  color: #7c7c7c;
}

.resend-otp {
  color: $primaryThemeColor;
  cursor: pointer;
  margin: 10px 0px;
  font-weight: 700;
  @media screen and (min-width: 768px) {
    top: 18px;
  }
  &.disabled {
    color: $lightGray;
    cursor: default;
    font-weight: 700;
  }
}

.back-label {
  font-size: 16px;
  margin: auto 0px;
  @media screen and (max-width: 572px) {
    font-size: 14px;
  }
}

.theme-color {
  color: $primaryThemeColor;
}

.mobile-deep-linking {
  font-size: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 2rem;
  max-height: calc(100vh);
  height: calc(100vh - 150px);
  padding: 0 50px;
  font-weight: 400;
}

.patient-forgot-password {
  display: block;
  padding: 16px 0px;
  font-weight: 700;
  color: $primaryThemeColor;
}
