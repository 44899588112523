@import 'assets/stylesheets/variables.scss';

.conversation-list-row {
  .font-size-17 {
    font-size: 17px;
  }
  .conversation {
    display: flex;
  }
  .conversation-title {
    font-weight: 700;
    font-size: 17px;
  }
  .date {
    color: $lightGray;
    font-weight: 300;
  }
  .sent-on {
    font-weight: 100;
  }
  .dot:before {
    content: ' \2022 ';
    color: $lightGray;
    margin-left: 2px;
    margin-right: 5px;
  }
  .margin-top-5 {
    margin-top: 5px;
  }
  .font-weight-300 {
    font-weight: 300;
  }
  .font-size-sm {
    font-size: 14px;
  }
  .app-link {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  border: 1px solid #e6e6e6;
  padding: 20px;
}
.go-to-thread-btn {
  width: 177px;
  align-self: flex-end;
  cursor: pointer;
}
.go-to-thread-btn-text {
  font-size: 1em;
  margin: -5%;
  position: relative;
  top: 6px;
  @media (max-width: 768px) {
    position: inherit;
    font-size: 1em;
    margin: -5%;
  }
}

.go-to-thread-icon {
  position: relative;
  top: 8px;
  right: -18px;
  @media (max-width: 768px) {
    position: relative;
    top: 2px;
    left: 18px;
  }
}
