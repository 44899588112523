@import 'assets/stylesheets/variables.scss';

.content-administration {
  margin-top: 24px;
  .add-new-link {
    color: $primaryThemeColor;
    cursor: pointer;
    margin-right: 20px;

    .icon {
      position: relative;
      top: 3px;
      margin-right: 5px;
    }
  }

  .ui-table {
    max-height: calc(100vh - 250px);
    display: block;
    overflow: scroll;
    width: 100%;

    .sticky-header {
      background: $white;
      color: $black;
      top: 0px;
      position: sticky;
      z-index: 2;
    }
  }

  .user-table {
    thead {
      width: 71%;
      background-color: white;
      position: relative;
      margin-top: -73px;
      @media screen and (max-width: 768px) {
        width: 67%;
      }
      @media screen and (min-width: 1024px) and (max-width: 1336px) {
        width: 70.5%;
      }
    }
  }

  .location-table {
    thead {
      width: 71.5%;
      background-color: white;
      margin-top: -79px;
      @media screen and (max-width: 768px) {
        width: 64.3%;
      }
      @media screen and (min-width: 1024px) and (max-width: 1336px) {
        width: 67%;
      }
    }
  }

  table {
    margin-top: 25px;
    th {
      border-right: 1px solid white;
    }
  }

  .table-td-align-middle {
    td {
      // vertical-align: middle;

      .icon {
        display: inline-block;
        padding: 3px 10px;
      }

      .icon-font-a-write-message {
        color: $primaryThemeColor;
        padding-left: 0;
      }
    }
  }

  .inline-btn {
    margin-right: 10px;
  }
}

.content-error {
  font-size: 24px;
  font-weight: 600;
  margin-top: 50px;
  text-align: center;
}

.filter-container-wrapper {
  .filters-container {
    padding: 0 5px;
    .filters-label {
      font-size: 16px;
      padding: 15px 0px;
    }
    .col-xs-12 {
      label {
        padding-top: 10px;
      }
    }
    .filter-wrapper {
      padding-bottom: 20px;
      label {
        font-weight: 300;
      }
    }
    .btn-wrapper {
      margin-top: 10px;
      text-align: center;
      .btn {
        margin: 5px 5px;
      }
    }
  }
}

.record-list-wrapper {
  border-left: 1px solid $lightGray;
  margin-top: 56px;
  .pagination {
    margin: 0;
    float: right;
  }
}
.admin-navigation-div {
  margin-top: -25px;
  padding-top: 25px;
  position: fixed;
  z-index: 1;
  width: 96%;
  background-color: white;
  .admin-navigation-section {
    border: 1px solid $primaryThemeColor;
    border-radius: 4px;
    .nav-pills > .active > a {
      outline: none;
      text-decoration: none;
      color: $primaryThemeColor;
      font-weight: 500;
      background: $lightTabColor;
    }
    .nav-pills > li:first-child {
      border-right: 1px solid $primaryThemeColor;
    }
    .nav-pills > li > a {
      color: $primaryThemeColor;
      padding: 5px 15px;
      .icon-label {
        font-size: 14px;
        line-height: 2;
      }
      .icon::before {
        font-size: 18px;
        top: 4px;
      }
    }
  }
}

.admin-title-container {
  padding-right: 20px;
  padding-left: 20px;
  // overflow-x: hidden;
  .tab-details-title {
    padding: 2px 1px;
    .title {
      vertical-align: text-bottom;
    }
  }
  .tab-content-wrapper {
    margin-top: 0px;
  }
}
.tab-details-title {
  padding: 2px 1px;
  .title {
    vertical-align: text-bottom;
  }
}

input[type='number'].no-spinner::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
