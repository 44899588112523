@import 'assets/stylesheets/variables.scss';

@import 'assets/stylesheets/variables.scss';
.lookup {
  margin-top: 15px !important;

  &.navbar-left {
    float: right !important;
  }

  .form-group {
    vertical-align: top;

    .help-block {
      font-size: 12px;
      font-weight: 100;
    }
    input::-webkit-input-placeholder {
      font-size: 14px;
      font-weight: 400;
    }
  }

  input {
    border: 1px solid lightgray;
    background: white;
    margin: 0;
    height: 32px;
    font-size: 14px;
    min-width: 260px;

    @media screen and (max-width: 875px) {
      min-width: 185px;
      font-size: 12px;
    }
  }

  .btn-primary {
    vertical-align: top;
    background: $buttonGradient;
    height: 32px;
    line-height: 20px;
    padding: 5px 12px;
    font-weight: 300;
    font-size: 14px;
    border-radius: 0px 5px 5px 0px;
    border: none;
    &:focus,
    &:hover {
      border: none !important;
    }
  }
}

@media screen and (max-width: 875px) {
  .navbar-form {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
