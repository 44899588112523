@import 'assets/stylesheets/variables.scss';

.modal-header {
  background-color: $primaryThemeColor;
}
.modal-text {
  margin-top: -4px;
  font-weight: 300;
  text-align: center;
}
.confirm-modal_btns_container {
  margin-top: 15px;
}
.contained-modal-title {
  color: white;
}

.buttons-center {
  display: flex;
  justify-content: center;
  gap: 26px;
}

.btn-primary-conformation-modal {
  background-color: $primaryThemeColor !important;
  border: 1px $primaryThemeColor !important;
  height: 35px !important;
  border-radius: 20px !important;
  padding: 0px 25px !important;
  font-weight: 300 !important;
  font-size: 16px !important;
  color: white;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    height: 30px;
  }
  &:hover {
    background-color: $defaultBtnHoverColor;
    // border: 1px solid $defaultBtnHoverColor;
    .icon:before {
      color: white;
    }
  }
}
