@import 'assets/stylesheets/variables.scss';

.disclaimer-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  background-color: #f8edde;
  margin: 67px -15px 0px -15px;
  padding: 10px 40px;
  right: 0px;
}

.message-disclaimer-text {
  margin-left: -21px;
}

.input-control-class {
  position: relative;
  top: 15px;
  input {
    height: 48px;
    border-radius: 10px;
    margin-bottom: 24px;
  }
}

.message-text-area-control {
  position: relative;
  .text_area {
    width: 100%;
    height: 100px;
    padding: 10px;
    border-radius: 10px;
    height: 244px;
    margin: 15px 0px;
    &:focus {
      box-shadow: none;
    }
  }
}

.conversation-back {
  margin-top: 10.17px;
  @media screen and (min-width: 767px) {
    margin-top: 12.17px;
  }
}

.conversation-height {
  min-height: 80vh !important;
}

.conversation-block {
  padding: 6px 25px;
}

.messaging_block {
  margin: 0px 25px;
  .input-control-class {
    margin-top: 20px;
  }
}

.back-btn {
  margin-left: 10px;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    .btn-text {
      display: none;
    }
    padding-right: 5px;
  }
  position: relative;
  width: fit-content;
  height: 32.5px;
  margin-top: 4px;
  // margin-left:37.17px;
  padding-top: 4px;
  /* Absolute Blue */
  background: $primaryThemeColor;
  border: 0.833333px solid $primaryThemeColor;
  box-sizing: border-box;
  border-radius: 25px;
  align-self: center;
  justify-self: center;
}
.btn-text {
  width: 36.67px;
  height: 20px;
  margin-left: 5.83px;
  margin-right: 5.83px;
  font-style: normal;
  font-weight: normal;
  font-size: 16.6667px;
  line-height: 20px;
  padding-right: 10px;
  /* identical to box height, or 120% */
  /* Basic White */
  color: #ffffff;
}
.send-message-header {
  display: flex;
}
.send-message-text {
  align-self: center;
  justify-self: center;
  position: relative;
  left: 28.5%;
  // margin-left:22.5%;
}
.select-procedure-text {
  position: relative;
  height: 24px;

  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  /* Absolute Blue */

  color: $primaryThemeColor;
}
.placeholder-text {
  position: relative;
  width: 272px;
  height: 24px;

  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  /* Inactive Grey */

  color: #979698;
}

.input-message {
  height: 40px;
}

.title {
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  padding: 17px 0px 0px 20px;
}
.attachment-btn-container {
  position: relative;
  margin: 15px 0px;
  padding: 10px 0px;

  .attachment-btn {
    color: blue;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    .icon:before {
      color: $primaryThemeColor;
      font-size: 30px;
      top: 3px;
    }
    .icon:hover:before {
      font-size: 30px;
      top: 3px;
    }
  }
  .attachment-btn:before {
    font-size: 32px;
    color: $primaryThemeColor;
  }
}
.conversations__container {
  background: $white;
  border-top: 3px solid $primaryThemeColor;
}

.conversation-block-padding {
  padding-left: 10px;
  padding-right: 10px;
}

.conversation_block {
  border-bottom: 1px solid #c9c9c9;
  padding-bottom: 10.5px;
}

.type-title {
  position: relative;
  font-weight: 700;
  font-size: 22px;
  margin: 28px 0px 17.17px 0px;
}

.subject {
  font-weight: 600;
  font-size: 17px;
  margin-top: 15px;
  margin-bottom: 16px;
  left: -15px;
}

.description {
  position: relative;
  font-weight: 400;

  .description-title {
    font-size: 22px;
  }

  .description-body {
    font-size: 17px;
  }
}
