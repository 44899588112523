@charset "UTF-8";
@font-face {
  font-family: 'mms-font-a';
  src: url('../fonts/mms-font/mms-font-a.eot') format('eot');
  src: url('../fonts/mms-font/mms-font-a.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/mms-font/mms-font-a.woff') format('woff'),
    url('../fonts/mms-font/mms-font-a.ttf') format('truetype'),
    url('../fonts/mms-font/mms-font-a.svg#mms-font-a') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

[data-icon]:before {
  font-family: 'mms-font-a' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 24px;
  position: relative;
  top: 4px;
}

[class^='icon-font-a-']:before,
[class*=' icon-font-a-']:before {
  font-family: 'mms-font-a' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 19px;
  position: relative;
  top: 2px;
}

.icon-font-a-manage-procedure:before {
  content: '\61';
  text-transform: lowercase !important;
}
.icon-font-a-arrival-time:before {
  font-size: 12px;
  content: '\63';
  text-transform: lowercase !important;
}
.icon-font-a-location:before {
  content: '\64';
  text-transform: lowercase !important;
}
.icon-font-a-task-due:before {
  content: '\65';
  text-transform: lowercase !important;
}
.icon-font-a-appointment-question:before {
  content: '\66';
  text-transform: lowercase !important;
}
.icon-font-a-procedure-unfilled:before {
  content: '\68';
  text-transform: lowercase !important;
}
.icon-font-a-procedure-filled:before {
  content: '\69';
  text-transform: lowercase !important;
}
.icon-font-a-questions-unfilled:before {
  content: '\6a';
  text-transform: lowercase !important;
}
.icon-font-a-questions-filled:before {
  content: '\6b';
  text-transform: lowercase !important;
}
.icon-font-a-tasks-unfilled:before {
  content: '\6c';
  text-transform: lowercase !important;
}
.icon-font-a-tasks-filled:before {
  content: '\6d';
  text-transform: lowercase !important;
}
.icon-font-a-new-task:before {
  content: '\6e';
  text-transform: lowercase !important;
}
.icon-font-a-caregiver:before {
  content: '\6f';
  text-transform: lowercase !important;
}
.icon-font-a-profile:before {
  content: '\70';
  text-transform: lowercase !important;
}
.icon-font-a-add-caregiver:before {
  content: '\71';
  text-transform: lowercase !important;
}
.icon-font-a-calendar:before {
  content: '\72';
  text-transform: lowercase !important;
}
.icon-font-a-attachment:before {
  content: '\73';
  text-transform: lowercase !important;
}
.icon-font-a-search-question:before {
  content: '\74';
  text-transform: lowercase !important;
}
.icon-font-a-add-question:before {
  content: '\75';
  text-transform: lowercase !important;
}
.icon-font-a-more:before {
  content: '\76';
  text-transform: lowercase !important;
}
.icon-font-a-notification-filled:before {
  content: '\77';
  text-transform: lowercase !important;
}
.icon-font-a-notification-unfilled:before {
  content: '\78';
  text-transform: lowercase !important;
}
.icon-font-a-tasks:before {
  content: '\7a';
  text-transform: lowercase !important;
}
.icon-font-a-question:before {
  content: '\45';
  text-transform: uppercase !important;
}
.icon-font-a-notification:before {
  content: '\46';
  text-transform: uppercase !important;
}
.icon-font-a-add-new-patient:before {
  content: '\48';
  text-transform: uppercase !important;
}
.icon-font-a-send-message:before {
  content: '\49';
  text-transform: uppercase !important;
}
.icon-font-a-write-message:before {
  content: '\4a';
  text-transform: uppercase !important;
}
.icon-font-a-pending:before {
  content: '\4b';
  text-transform: uppercase !important;
}
.icon-font-a-check-mark:before {
  content: '\4c';
  text-transform: uppercase !important;
}
.icon-font-a-add-new-task:before {
  content: '\4d';
  text-transform: uppercase !important;
}
.icon-font-a-delete:before {
  content: '\4e';
  text-transform: uppercase !important;
}
.icon-font-a-change-procedure:before {
  content: '\50';
  text-transform: uppercase !important;
}
.icon-font-a-patient-under-physician:before {
  content: '\4f';
  text-transform: uppercase !important;
}
.icon-font-a-sort-by:before {
  content: '\53';
  text-transform: uppercase !important;
}
.icon-font-a-filters:before {
  content: '\54';
  text-transform: uppercase !important;
}
.icon-font-a-close:before {
  content: '\55';
  text-transform: uppercase !important;
}
.icon-font-a-tick:before {
  content: '\56';
  text-transform: uppercase !important;
}
.icon-font-a-stethoscope:before {
  content: '\57';
  text-transform: uppercase !important;
}
.icon-font-a-patient-with-unanswered-question:before {
  content: '\42';
  text-transform: uppercase !important;
}
.icon-font-a-patient-with-feedback:before {
  content: '\43';
  text-transform: uppercase !important;
}
.icon-font-a-patient-with-tbd:before {
  content: '\51';
  text-transform: uppercase !important;
}
.icon-font-a-patients-in-next-week:before {
  content: '\44';
  text-transform: uppercase !important;
}
.icon-font-a-patient-in-this-week:before {
  content: '\52';
  text-transform: uppercase !important;
}
.icon-font-a-me:before {
  content: '\58';
  text-transform: uppercase !important;
}
.icon-font-a-right-chevron:before {
  content: '\67';
  text-transform: lowercase !important;
}
.icon-font-a-left-chevron:before {
  content: '\59';
  text-transform: uppercase !important;
}
.icon-font-a-up-chevron:before {
  content: '\5a';
  text-transform: uppercase !important;
}
.icon-font-a-down-chevron:before {
  content: '\30';
}
.icon-font-a-forward-case:before {
  content: '\31';
}
.icon-font-a-attachment-pdf:before {
  content: '\36';
}
.icon-font-a-double-left-chevron:before {
  content: '\37';
}
.icon-font-a-double-right-chevron:before {
  content: '\38';
}
.icon-font-a-surveys:before {
  content: '\39';
}
.icon-font-a-clear-all:before {
  content: '\21';
}
.icon-font-a-write-a-custom-message:before {
  content: '\22';
}
.icon-font-a-error-message:before {
  content: '\23';
}
.icon-font-a-tick-unfilled:before {
  content: '\24';
}
.icon-font-a-sound-file:before {
  content: '\33';
}
.icon-font-a-re-assigned-patient:before {
  content: '\26';
}
.icon-font-a-system-generated:before {
  content: '\27';
}
.icon-font-a-manage-patient-advocate:before {
  content: '\28';
}
.icon-font-a-most-important-filter:before {
  content: '\29';
}
.icon-font-a-patient-info:before {
  content: '\2a';
}
.icon-font-a-unseen:before {
  content: '\2b';
}
.icon-font-a-seen:before {
  content: '\2c';
}
.icon-font-a-physician-info:before {
  content: '\2d';
}
.icon-font-a-recurring-task:before {
  content: '\2e';
}
.icon-font-a-attachment-jpeg:before {
  content: '\34';
}
.icon-font-a-patient-advocate:before {
  content: '\2f';
}
.icon-font-a-check-box:before {
  content: '\3a';
}
.icon-font-a-mms-feedback:before {
  content: '\3b';
}
.icon-font-a-edit-appointment:before {
  content: '\3c';
}
.icon-font-a-walkthrough:before {
  content: '\3d';
}
.icon-font-a-contact:before {
  content: '\3e';
}
.icon-font-a-email:before {
  content: '\3f';
}
.icon-font-a-neither-helpful-nor-unhelpful:before {
  content: '\40';
}
.icon-font-a-not-at-all-helpful:before {
  content: '\5b';
}
.icon-font-a-somewhat-helpful:before {
  content: '\5d';
}
.icon-font-a-somewhat-unhelpful:before {
  content: '\5e';
}
.icon-font-a-very-helpful:before {
  content: '\5f';
}
.icon-font-a-neither-helpful-nor-unhelpful-filled:before {
  content: '\60';
}
.icon-font-a-somewhat-helpful-filled:before {
  content: '\7b';
}
.icon-font-a-somewhat-unhelpful-filled:before {
  content: '\7c';
}
.icon-font-a-very-helpful-filled:before {
  content: '\7d';
}
.icon-font-a-not-at-all-helpful-filled:before {
  content: '\7e';
}
.icon-font-a-links:before {
  content: '\5c';
}
.icon-font-a-print:before {
  content: '\47';
  text-transform: uppercase !important;
}
.icon-font-a-resend-invite:before {
  content: '\62';
  text-transform: lowercase !important;
}
.icon-font-a-connect:before {
  content: '\79';
  text-transform: lowercase !important;
}
.icon-font-a-attachment-tilt:before {
  content: '\41';
  text-transform: uppercase !important;
}
.icon-font-a-attachment-circle:before {
  content: '\32';
}
.icon-font-a-helpful-box:before {
  content: '\35';
}
.icon-font-a-not-helpful-box:before {
  content: '\e000';
}
.icon-font-a-resources:before {
  content: '\e002';
}
.icon-font-a-helpful:before {
  content: '\e003';
}
.icon-font-a-not-helpful:before {
  content: '\e004';
}
.icon-font-a-outcome-reports:before {
  content: '\e005';
}
.icon-font-a-notification2:before {
  content: '\e006';
}
.icon-font-a-appointments2:before {
  content: '\e007';
}
.icon-font-a-switch-patient:before {
  content: '\e008';
}
.icon-font-a-summary:before {
  content: '\e009';
}
.icon-font-a-helpful-filled:before {
  content: '\25';
}
.icon-font-a-not-helpful-filled:before {
  content: '\e001';
}
