@import 'assets/stylesheets/variables.scss';

.header-section {
  display: flex;
  padding: 24px;
  flex: 1;
  .logo-section {
    flex: 0.08;
    .logo {
      width: 40px;
      height: 36px;
    }
  }
}

.header-grid {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr 1fr;
}

.header-grid-item {
  display: flex;
  padding: 10px;
  font-size: 24px;
  line-height: 24px;
  margin-top: auto;

  .logout-container {
    gap: 10px;
    display: flex;
    flex: 0 0 auto;
    margin-right: 32px;
    color: #a3001e;
    cursor: pointer;
  }
}

.title-signout {
  display: flex;
  justify-content: center;
}
.logout {
  display: flex;
  justify-content: end;
}
