@import 'assets/stylesheets/variables.scss';

.sendMessage {
  display: inline-block;
  position: relative;
  font-size: 16px;
  font-weight: normal;
  top: -3px;
  justify-self: flex-start;
  // @media screen and (max-width: 767px) {
  //   display: block;
  //   top: 4px;
  // }
}
.messages-container {
  background: white;

  .action__btn {
    color: $primaryThemeColor;
    border: 1px solid $primaryThemeColor;
    border-radius: 20px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
}

.myMessageText {
  // display: inline-block;
  // position: relative;
  // font-size: 16px;
  // font-weight: normal;
  // top: 6px;
  // @media screen and (max-width: 767px) {
  //   display: block;
  //   top: 4px;
  // }
  // top:11px;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  /* Insightec Black */

  color: $insBlack;
}

.conversations__container {
  background-color: white;
  padding: 20px 20px 40px 20px;
}

.messaging-header {
  // padding-left:0;
  // padding-right:0;
  // display:flex;
  height: 46px;
}

.my-message-container {
  position: relative;
  top: 18.67px;
}
.send-message-container {
  padding: 15px;
  .icon-font-a-add-new-task::before {
    top: 3px;
  }
  .sendMessage {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: $primaryThemeColor;
  }
}
