@import 'assets/stylesheets/variables.scss';
.welcome-modal-header {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  .welcome-header {
    margin-top: 35px;
    margin-bottom: 21px;
    padding-top: 15px;
    font-size: 34px;
    font-weight: 100;
    @media screen and (max-width: 572px) {
      margin-top: 20px;
      margin-bottom: 7px;
      font-size: 24px;
    }
    @media screen and (max-width: 320px) {
      font-size: 22px;
    }
  }
  .welcome-header-separator {
    width: 80%;
    margin: 0 auto;
    border-top: 1px solid $borderColor;
    @media screen and (max-width: 572px) {
      width: 90%;
    }
  }

  .welcome-header-body {
    margin-top: 18px;
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 500;
    color: $primaryThemeColor;
    @media screen and (max-width: 572px) {
      margin-top: 7px;
      margin-bottom: 5px;
      font-size: 14px;
    }
  }
}

.welcome-modal-body {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  margin-top: 7px;

  .welcome-modal-body-text {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 22px;
    @media screen and (max-width: 572px) {
      margin-bottom: 15px;
      font-size: 12px;
    }

    &.last {
      margin-top: 40px;
      margin-bottom: 35px;
      @media screen and (max-width: 572px) {
        margin-top: 20px;
        margin-bottom: 30px;
      }
    }
  }
}
