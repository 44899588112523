.trad-base-grid {
  display: grid;
  height: 100vh;
  grid-template-columns: 16.4% 1fr;
}

.grid-closed {
  grid-template-columns: 16.4% 1fr;
}
.grid-open {
  grid-template-columns: 6.3% 1fr;
}

.header-grid {
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
}

.header-grid-item {
  flex: 1; /* Distribute available space equally among flex items */
  padding: 10px;
  text-align: center;
}

// .patient-table{
//   font-family: Arial, Helvetica, sans-serif;
//   border-collapse: collapse;
//   width: 100%;
// }

// .patient-table td, .patient-table th {
//   border: 1px solid #ddd;
//   padding: 8px;
// }

.patient-table .th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #f9fafb;
  color: white;
}

.dashboard-wrapper {
  display: flex;
  height: 100vh;
  .data-show-wrapper {
    width: 100%;
    overflow: auto;
    .no-order-image-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: calc(100vh - 130px);
    }
  }
}
