@import 'assets/stylesheets/variables.scss';

.input-container {
  margin-top: 10px;
  @media screen and (max-width: 572px) {
    margin-top: 30px;
  }

  .input-label-with-value {
    position: absolute;
    color: $gray !important;
    top: -13px;
    font-weight: 100;
    font-size: 12px;
    @media screen and (max-width: 572px) {
      top: -11px;
    }
  }
  .input-label-without-value {
    position: absolute;
    font-size: 16px;
    font-weight: 100;
    top: 5px;
    color: $gray;
    @media screen and (max-width: 572px) {
      font-size: 14px;
    }
  }
  input {
    height: 30px;
    color: black;
    border: none;
    border-bottom: 1px solid $gray !important;
    border-radius: 0;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 5px;
    box-shadow: none !important;
    padding-left: 0px;

    @media screen and (max-width: 572px) {
      font-size: 14px;
    }

    &:focus {
      border-bottom-color: $primaryThemeColor;
    }
  }
  .input-label-is-focused {
    color: $primaryThemeColor !important;

    &.input-label-without-value {
      color: $gray !important;
    }
  }
}
