@import 'assets/stylesheets/variables.scss';

.forgot-password-small-screen {
  color: $primaryThemeColor;
  position: relative;
  left: -107px;
  span:hover {
    color: $insBlack;
  }
  @media screen and (min-width: 568px) {
    left: -128px;
  }
}

.signup-btn-small-screen {
  position: relative;
  left: 1px;
  top: 19px;
}

.input-border {
  padding: 2px;
  border: 1px solid #72788a;
  border-radius: 3.84615px;
}

.input-border-blue {
  padding: 2px;
  border: 1px solid $primaryThemeColor;
  border-radius: 3.84615px;
}

.button-container {
  button {
    margin-top: 65px;
    position: relative;
    bottom: 8px;
    //margin: 0px;
    width: 350px;
    height: 57.69px;
    left: 1px;
    //top: 719.74px;
    background: $insBlack;
    border-radius: 3.84615px;
    //top: 419.74px;
    //left: 50%;
  }
  button:hover {
    background-color: $primaryThemeColor;
  }
  button:active,
  button:focus {
    background-color: $primaryThemeColor !important;
  }
}

.signin-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  height: 100%;

  .login-form-container {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center; //inner container
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: calc(100vh - 60px);

    .login-form-view {
      min-width: 400px;
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 100%;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto; //inner content
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-align-content: stretch;
      -ms-flex-line-pack: stretch;
      align-content: stretch;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: stretch;

      @media screen and (max-width: 768px) {
        padding-right: 20px;
      }

      .btn {
        min-width: 200px;
      }
    }

    .pre-login-footer-container {
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto;
    }
  }

  .image-and-process-container {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;

    .background-image-container {
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      align-self: center;
      width: 100%;
      height: 245px;
      background-image: url(../../assets/images/hero_image_v2_ins.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 0;
      color: #ffffff;

      @media screen and (min-width: 1024px) and (max-width: 1440px) {
        height: 264px;
      }

      @media screen and (min-width: 1441px) and (max-width: 2560px) {
        background-image: url(../../assets/images/hero_image.png);
        height: 522px;
      }

      .header-content {
        top: 30px;
        font-size: 30px;
        line-height: 34px;
        font-weight: 700;

        @media screen and (min-width: 1024px) and (max-width: 1440px) {
          font-size: 35px;
          line-height: 45px;
        }

        @media screen and (min-width: 1441px) and (max-width: 2560px) {
          font-size: 65px;
          line-height: 85px;
        }
      }
    }

    .portal-container {
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto; //for inner arrangement
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      background: $insBlack;
      color: #fff;
      font-size: 12px;
      font-weight: 100;
      row-gap: 40px;

      @media screen and (min-width: 1441px) and (max-width: 2560px) {
        font-size: 24px;
      }

      .patient-learn-more-container,
      .provider-learn-more-container,
      .combination-content {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        align-self: center;
        display: flex;
        align-items: center;
        .content {
          margin-left: 20px;
        }
        .icon:before {
          font-size: 36px;
          padding-left: 20px;
          padding-right: 20px;
        }
      }

      .patient-learn-more-container {
        font-size: 14px;
        width: 100%;
        .icon {
          &:before {
            @media screen and (min-width: 1441px) and (max-width: 2560px) {
              font-size: 48px;
            }
          }
        }

        .link {
          display: block;
          color: $primaryThemeColor;
          font-weight: 500;
        }
      }

      .provider-learn-more-container {
        font-size: 14px;
        margin-top: 10px;

        .icon {
          left: 15px;

          &:before {
            @media screen and (min-width: 1441px) and (max-width: 2560px) {
              font-size: 48px;
            }
          }
        }

        .link {
          display: block;
          color: $primaryThemeColor;
          font-weight: 500;
        }
      }

      .combination-content {
        font-size: 12px;
        text-align: center;
        margin-top: 15px;

        @media screen and (min-width: 1441px) and (max-width: 2560px) {
          font-size: 24px;
        }
      }
    }
  }
}

.login-form-container {
  .header {
    font-size: 30px;
    font-weight: 400;
    // margin: 0px 0 20px 0;

    @media screen and (min-width: 736px) and (max-width: 768px) {
      margin: 10px 0;
      font-size: 26px;
    }
  }

  .sub-header {
    font-size: 20px;
    font-weight: 300;
    margin: 0px 0 20px 0;

    @media screen and (min-width: 736px) and (max-width: 768px) {
      margin: 10px 0;
      font-size: 20px;
    }
  }

  .logo-container {
    margin-bottom: 30px;

    @media screen and (max-width: 768px) {
      margin-bottom: 0px;
    }

    img {
      width: 200px;
    }
  }

  .forgot-password {
    position: relative;
    // margin-left: -35px;
    left: 3px;
    font-size: 14px;
    font-weight: 500;
    color: $primaryThemeColor;
    margin-top: -3px;
    margin-bottom: 15px;

    span:hover {
      color: $insBlack;
    }
  }

  .field-description {
    font-size: 12px;
    font-weight: 300;
    color: $gray;
  }

  .password-show {
    position: absolute;
    right: 10px;
    top: 15px;
    color: $primaryThemeColor;
    cursor: pointer;

    &.disabled {
      color: $disabledColor;
      cursor: default;
    }
  }

  .term-and-condition-container {
    margin-top: 15px;
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 300;

    @media screen and (max-width: 768px) {
      font-size: 14px;
      margin-bottom: 10px;
    }

    .icon:before {
      font-size: 30px;
      top: 10px;
      left: -2px;
      color: $primaryThemeColor;

      @media screen and (max-width: 768px) {
        font-size: 30px;
        top: 5px;
      }
    }

    .term-and-condition-link {
      color: $primaryThemeColor;
    }
  }

  .error-for-term {
    display: block;
    clear: both;
    font-size: 12px;
    font-weight: 300;
    margin-left: 35px;
    color: $red;
  }

  .signup-link-container {
    margin-top: 20px;

    .btn {
      margin-left: 15px;
    }
  }
}

.pre-login-process-image-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  background: $preLoginImageBackground;
  width: 100%;
  height: 100%;
  margin-left: 20px;
}

.terms-and-conditions-modal {
  .modal-dialog {
    width: 500px;
    @media screen and (max-width: 420px) {
      width: 90%;
    }
  }
  #contained-modal-title {
    font-size: 18px;
    font-weight: 100;
    @media screen and (max-width: 375px) {
      font-size: 16px;
    }
    @media screen and (max-width: 320px) {
      font-size: 14px;
    }
  }

  .term-and-condition-container {
    .icon:before {
      font-size: 24px;
      top: 8px;
      color: $primaryThemeColor;
    }
    .term-and-condition-label {
      font-size: 17px;
      font-weight: 100;
      margin-left: 5px;
      margin-bottom: 1px;
    }

    .term-and-condition-link {
      color: $primaryThemeColor;
      font-weight: 300;
      margin-bottom: 20px;
    }
    .btn-default {
      margin-bottom: 10px;
    }

    .btn {
      min-width: 180px;
      height: 30px;
      font-size: 12px;
    }
  }
}

.otp-modal {
  .input-label-without-value,
  .input-label-with-value,
  .input-label-is-focused {
    left: 0;
  }
}

.timer {
  color: #7c7c7c;
}

.resend-code {
  color: #2b80bb;
}

// Mobile view
.mobile-view-container {
  @media screen and (min-width: 769px) {
    min-height: calc(100vh - 62px);
  }

  @media screen and (max-width: 768px) {
    min-height: calc(100vh - 52px);
  }

  @media screen and (max-width: 375px) {
    min-height: calc(100vh - 50px);
  }

  .back-to-login {
    position: absolute;
    left: 20px;

    &.icon:before {
      color: $primaryThemeColor;
    }
  }

  .logo-container {
    margin: 10px 0;

    @media screen and (min-width: 736px) and (max-width: 768px) {
      margin-bottom: 100px;
    }

    img {
      width: 100px;
    }
  }

  .header-text {
    font-size: 20px;
    font-weight: 100;
    text-align: center;
    margin: 10px 0;

    @media screen and (min-width: 736px) and (max-width: 768px) {
      margin: 50px;
    }

    span {
      display: block;
    }
  }

  .btn {
    margin: 5px 10px;
    min-width: 200px;
  }

  .forgot-password {
    font-size: 14px;
    font-weight: 500;
    color: $primaryThemeColor;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .password-show {
    position: absolute;
    right: 10px;
    top: 15px;
    color: $primaryThemeColor;
    cursor: pointer;

    &.disabled {
      color: $disabledColor;
      cursor: default;
    }
  }

  .error-for-term {
    display: block;
    clear: both;
    font-size: 12px;
    font-weight: 300;
    margin-left: 35px;
    color: $red;
  }

  // form section
  .signin-form-container {
    .page-title {
      text-align: center;
      background: #f5f5fa;
      padding: 20px 0;
      font-size: 30px;
      font-weight: 100;
      margin-top: 3px;
      margin-bottom: 50px;

      @media screen and (max-width: 320px) {
        margin-bottom: 20px;
      }
    }

    .page-form {
      display: flex;
      justify-content: center;
    }
  }

  .mobile-footer {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

    div {
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto;
    }

    background: $lightWhite;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
    font-weight: 300;

    .contact-us {
      color: $primaryThemeColor;
      margin-right: 30px;
      float: left;
    }

    .about-us {
      color: $primaryThemeColor;
      margin-left: 10px;
      float: left;
    }

    .dot {
      margin-left: 10px;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background: $gray;
      float: left;
    }

    .contact-number {
      color: $gray;
      margin-left: 10px;
      float: left;
    }
  }

  //new view changes
  .background-image-container {
    width: 100%;
    height: 236px;
    background-image: url(../../assets/images/mobile_hero_image_ins.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0;
    color: #ffffff;

    .header-content {
      top: 10px;
      font-size: 22px;
      line-height: 28px;
      font-weight: 700;
    }

    @media screen and (min-width: 568px) {
      background-image: url(../../assets/images/ipad_hero_image_v2_ins.png);
      height: 315px;

      .header-content {
        top: 30px;
        font-size: 36px;
        line-height: 43px;
        font-weight: 700;
      }
    }

    @media screen and (max-width: 320px) {
      height: 200px;

      .header-content {
        top: 20px;
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;
      }
    }

    .signin-signup-button-container {
      margin-top: 40px;

      button {
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        align-self: center;
        width: 150px;
        margin: 5px 0;
        padding: 0;

        @media screen and (max-width: 320px) {
          width: 120px;
        }
      }
    }
  }

  .portal-container {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;

    background: #000;
    color: #fff;
    padding: 20px 10px;
    font-size: 12px;
    font-weight: 100;
    .signin-signup-button-row-container {
      .btn {
        width: 200px;
        height: 35px;
        margin: 20px 0;
      }
    }

    .row-sep {
      border-bottom: 1px solid $borderColor;
      margin: 15px 0 20px 30px;
    }

    .login-info-content {
      display: flex;
      align-items: center;
      flex-direction: column;
      row-gap: 20px;
    }

    .patient-learn-more-container {
      font-size: 14px;
      display: flex;
      align-items: center;
      width: 100%;

      .content {
        width: 100%;
      }

      .icon {
        left: 15px;

        &:before {
          @media screen and (min-width: 1441px) and (max-width: 2560px) {
            font-size: 48px;
          }
        }
      }

      .link {
        display: block;
        color: $primaryThemeColor;
        font-weight: 500;
        font-size: 14px;
      }
    }

    .provider-learn-more-container {
      margin-top: 10px;
      font-size: 14px;

      display: flex;
      align-items: center;

      .icon {
        left: 15px;

        &:before {
          @media screen and (min-width: 1441px) and (max-width: 2560px) {
            font-size: 48px;
          }
        }
      }

      .link {
        display: block;
        color: $primaryThemeColor;
        font-weight: 500;
        font-size: 14px;
      }
    }

    .patient-learn-more-container,
    .provider-learn-more-container {
      .content {
        margin-left: 20px;
        // top: 10px;
      }
      .icon:before {
        font-size: 36px;
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .separation-line {
      border-top: 1px solid #4a4a4a;
      margin: 20px 0;
    }

    .combination-content {
      font-size: 12px;
      text-align: center;
      margin-top: 15px;
    }

    @media screen and (max-width: 320px) {
      padding: 10px 10px;

      .combination-content {
        margin-top: 10px;
      }
    }

    @media screen and (min-width: 736px) {
      padding: 20px 50px;
      font-size: 18px;

      .combination-content {
        font-size: 14px;
      }
    }

    @media screen and (min-width: 768px) {
      padding: 50px 50px;
      font-size: 18px;

      .combination-content {
        margin-top: 50px;
      }
    }
  }

  .page-container {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    height: 100%;

    .background-image-container,
    // .portal-container,
    .mobile-footer {
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto;
    }
  }
}

.back-button {
  color: $primaryThemeColor;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 50px;
  margin-top: 30px;
}

.custom-video-container {
  .modal-dialog {
    width: auto;

    .modal-body {
      padding: 0;
      width: calc(78vw);
      height: calc(90vh);

      @media screen and (max-width: 768px) {
        width: calc(85vw);
        height: calc(60vh);
      }

      @media screen and (max-width: 768px) {
        width: calc(81vw);
        height: calc(31vh);
      }
    }

    #video {
      height: 100%;
      width: 100%;
    }
  }
}

.invite-code-footer {
  font-size: 12px;
  font-weight: 300;
}
