@import 'assets/stylesheets/variables.scss';
.header-container {
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.5);
  margin-bottom: 0px;
  img {
    width: 155px; // width: 200px;
  }
  .navbar-brand {
    height: auto;
    // margin-left: -10px !important;
    // padding: 5px 15px;
  }
  .navbar-right {
    margin-right: 0;
  }
  .nav {
    li {
      a {
        line-height: 42px;
      }
    }
  }
  .user-info-container {
    min-width: 380px;
    margin-top: 8px;
    .user-image img {
      max-width: 100%;
      border-radius: 50%;
    }
    .user-name {
      font-weight: bold;
      margin: 2px 0;
      font-size: 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .user-email,
    .user-type {
      margin: 2px 0;
      font-size: 14px;
      font-weight: 300;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .user-information {
      padding: 0;
      padding-right: 5px;
      .btn-primary {
        line-height: 22px;
        height: 34px;
        margin-top: 2px;
      }
    }
  }
  .nav-notification-icon {
    @keyframes pulse {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.3);
      }
      100% {
        transform: scale(1);
      }
    }
    .pending-notifications-badge {
      position: absolute;
      top: 18px;
      left: 40px;
      background: $badgeRed;
      line-height: 20px;
      border-radius: 10px;
      color: white;
      font-size: 12px;
      font-weight: 300;
      display: block;
      min-width: 20px;
      height: 20px;
      text-align: center;
      padding: 0 2px;
      animation: pulse 0.5s 1;
      @media screen and (max-width: 572px) {
        left: 26px;
        top: 15px;
      }
    }
    .animate-badge {
      animation: pulse 0.5s 1;
    }
  }
  .nav-notifications-container {
    min-width: 380px;
    height: 400px;
    margin-top: -5px;
    .nav-dropdown-header {
      background: $hoverColor;
      line-height: 50px;
      .nav-dropdown-title {
        font-size: 20px;
        color: $primaryThemeColor;
        font-weight: 400;
      }
      .see-all-link {
        font-size: 16px;
        color: $primaryThemeColor;
        font-weight: 700;
        cursor: pointer;
        &.disabled {
          color: $disabledColor;
          cursor: default;
        }
      }
    }
    .nav-dropdown-body {
      height: calc(400px - 46px);
      overflow: auto;
      .nav-notifications-empty-container {
        text-align: center;
        font-size: 20px;
        font-weight: 100;
        margin-top: 20px;
        .empty-container {
          .text {
            font-size: 12px;
            font-weight: 300;
            color: $emptyImageTextColor;
          }
        }
        img {
          width: 75px;
        }
      }
      .nav-notification-card {
        padding: 10px 15px;
        font-weight: 100;
        border-bottom: 1px solid $borderGreyColor;
        font-size: 16px;
        .timestamp {
          margin-top: 10px;
          color: $gray;
          font-weight: 300;
          font-size: 14px;
        }
        .content {
          color: black;
          font-weight: 300;
        }
        &.unread {
          .content,
          .timestamp {
            color: black;
            font-weight: 500;
          }
        }
      }
      .Wrapper-dyuznp {
        margin: 1em auto;
        width: 24px;
        height: 24px;
      }
    }
  }
  .dropdown-menu {
    &:before {
      content: ' ';
      position: absolute;
      top: -10px;
      right: 25px;
      width: 0;
      border-bottom: 10px solid $borderGreyColor;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
    &:after {
      content: ' ';
      position: absolute;
      top: -8px;
      right: 25px;
      width: 0;
      border-bottom: 10px solid #fff;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
  }
  .notification-navbar {
    a {
      text-align: center;
      min-width: 70px;
      &:hover {
        text-decoration: none;
      }
    }
    .dropdown-menu {
      &:after {
        border-bottom: 10px solid $hoverColor;
      }
    }
  }
  .dropdown-menu .divider {
    margin-bottom: 5px;
  }
  .menu-dropdown-link a {
    line-height: 26px !important;
    color: $primaryThemeColor;
    &:hover {
      color: $primaryThemeColor;
      background: none;
    }
  }
  .dropdown-menu > .active > a,
  .dropdown-menu > .active > a:hover,
  .dropdown-menu > .active > a:focus {
    color: #fff;
    text-decoration: none;
    background-color: $primaryThemeColor;
    outline: 0;
  }
}

@media screen and (max-width: 736px) {
  .custom-dropdown {
    .dropdown-menu {
      position: absolute !important;
      left: -250px !important;
      background: #fff !important;
      width: 320px !important;
      -webkit-background-clip: padding-box !important;
      background-clip: padding-box !important;
      border: 1px solid $lightGray !important;
      border: 1px solid $lightGray !important;
      border-radius: 4px !important;
      -webkit-box-shadow: 0 6px 12px $lightGray !important;
      box-shadow: 0 6px 12px $lightGray !important;
    }
  }
}
