@import 'assets/stylesheets/variables.scss';

.select-patient-card {
  //border: 1px solid $disabledColor;
  //border-radius: 5px;
  border-bottom: 1px solid $disabledColor;
  margin: 0px !important;

  &:last-child {
    //border-bottom: 0px;
  }
  &.modaly {
    border-radius: 0px;
    border: 0px;
    border-bottom: 1px solid $disabledColor;
    margin: 0px 0px !important;
    @media screen and (max-width: 572px) {
      font-size: 12px;
    }

    &:last-child {
      //border-bottom: 0px;
    }
  }

  .profile-cell {
    // Flex styles
    -webkit-order: 0;
    -ms-flex-order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    width: 80px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 15px;
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      @media screen and (max-width: 572px) {
        width: 60px;
        height: 60px;
      }
    }
    @media screen and (max-width: 572px) {
      width: 60px;
    }
  }

  .patient-info-container {
    // Flex styles
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .tick-container {
    padding-top: 35px;
    margin: auto 0;
  }
  .currently-viewing {
    color: $appGreen;
  }
}

div.select-patient-card:hover {
  background-color: $lightWhite;

  &.disabled {
    background-color: transparent;
  }
}
