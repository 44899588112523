@import 'assets/stylesheets/variables.scss';
@import '~rc-time-picker/assets/index.css';
.clear-icon {
  &.icon:before {
    font-size: 16px;
  }
}

.timer-icon {
  &.icon:before {
    font-size: 16px;
  }
}

.close-icon {
  font-size: 10px;
  border-radius: 0px;
  right: 3px;
  width: 33%;
  position: absolute;
  bottom: 0;
  background: $primaryThemeColor;
  line-height: 14px;
  color: $lightWhite;
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
}

.rc-time-picker {
  display: block !important;
  // border-bottom: 1px solid #9B9B9B !important;
  padding: 1px 0px;
  border: 1.5px solid #cccccc;
  border-radius: 5px;
  .rc-time-picker-input {
    font-size: 16px;
    width: 92%;
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
    height: 30px !important;
    border: none;
    border-radius: 0;
    padding: 5px;
    background: #fff;
  }
  .rc-time-picker-input[disabled] {
    background: #fff;
  }
  // input::placeholder {
  //   color: #555;
  //   font-weight: 300;
  // }
}

.rc-time-picker-panel {
  width: 100%;
  .rc-time-picker-panel-inner {
    border: none; // box-shadow: 0 1px 5px #ccc;
    box-shadow: none;
    width: 100%;
    .rc-time-picker-panel-input-wrap {
      padding: 5px;
    }
    .rc-time-picker-panel-combobox {
      .rc-time-picker-panel-select {
        width: 33.3%;
        overflow-x: hidden;
        border: 1px solid #e9e9e9;
        min-height: 144px;
        max-height: 144px;
        li {
          padding: 0 35%;
        }
      }
      .rc-time-picker-panel-select li:hover {
        background: #edfaff;
      }
      li.rc-time-picker-panel-select-option-selected {
        background: #edfaff;
        color: $primaryThemeColor;
      }
      li.rc-time-picker-panel-select-option-disabled {
        color: #bfbfbf;
      }
      li.rc-time-picker-panel-select-option-disabled:hover {
        background: transparent;
        cursor: not-allowed;
      }
    }
  }
}
