@import 'assets/stylesheets/variables.scss';

.masquerading-header {
  //margin: 0 15px;
  position: relative;
  // padding: 5px 5px;
  text-align: center;
  background: $masqueradingHeaderColor;
  font-weight: bold;
  color: #ffffff;
  @media screen and (max-width: 572px) {
    font-size: 13px;
  }
  display: flex;
  justify-content: center;
  border-width: thick;
  border-radius: 0px 0px 20px 20px;
  background-color: #7099b0;
  align-items: center;
  height: 48px;
  width: 387px;
  margin: auto;
  justify-self: right;
}
