@import 'assets/stylesheets/variables.scss';

.order-detail-modal {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  .patient-name {
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: left;
  }
  .order-details {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    .details-outer-wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      .title {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        padding: 0px;
        text-align: start;
      }
      .details-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 40px;
        .details {
          display: grid;
          grid-template-columns: 0.33fr 0.33fr 0.33fr;
          justify-content: space-between;
          column-gap: 32px;
          .key-value {
            display: flex;
            flex-direction: column;
            row-gap: 8px;
            // justify-content: space-between;
            .key {
              font-size: 14px;
              font-weight: 400;
              line-height: 18px;
            }
            .value {
              font-size: 18px;
              font-weight: 600;
              line-height: 18px;
            }
          }
        }
        .survey-details {
          display: flex;
          flex-direction: column;
          justify-content: start;
          .key-value {
            display: flex;
            flex-direction: column;
            row-gap: 8px;
            justify-content: space-between;
            .key {
              font-size: 14px;
              font-weight: 400;
              line-height: 18px;
            }
            .value {
              font-size: 18px;
              font-weight: 600;
              line-height: 18px;
            }
          }
        }
      }

      .survey-details-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 40px;
        .details {
          display: grid;
          grid-template-columns: 0.33fr 0.33fr 0.33fr;
          justify-content: space-between;
          column-gap: 32px;
          .key-value {
            display: flex;
            flex-direction: column;
            row-gap: 8px;
            // justify-content: space-between;
            .key {
              font-size: 14px;
              font-weight: 400;
              line-height: 18px;
            }
            .value {
              font-size: 18px;
              font-weight: 600;
              line-height: 18px;
            }
          }
        }
        .survey-details {
          display: flex;
          flex-direction: column;
          justify-content: start;
          .key-value {
            display: flex;
            flex-direction: column;
            row-gap: 8px;
            justify-content: space-between;
            .key {
              font-size: 14px;
              font-weight: 400;
              line-height: 18px;
            }
            .value {
              font-size: 18px;
              font-weight: 600;
              line-height: 18px;
            }
          }
        }
      }
    }
  }
}

.buttons-wrapper {
  display: flex;
  justify-content: center;
  padding: 40px 0 0 0;
  .complete-btn {
    width: 172px;
    height: 40px;
  }
}
