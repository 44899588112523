@import 'assets/stylesheets/variables.scss';
.select-procedure-container {
  margin-top: 30px;
  margin-bottom: 30px;
  .lbl-select-procedure {
    font-weight: 100;
    font-size: 30px;
    text-align: center;
    @media screen and (max-width: 572px) {
      font-size: 24px;
    }
  }
  .select-procedure-note {
    font-weight: 300;
    text-align: center;
    padding: 10px;
    color: $gray;
    @media screen and (max-width: 572px) {
      font-weight: 300;
    }
  }
  .procedure-container {
    border: 1px solid $primaryThemeColor;
    border-radius: 2px;
    @media screen and (max-width: 572px) {
      border: none;
    }
    .procedure-date {
      color: $gray;
      font-weight: 300;
    }
  }
}
