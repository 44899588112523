// Grid variables
$screen-xs-min: 480px;
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;
// Colors
$lightGray: #c9c9c9;
$faintGrey: #f7f2f2;
$platinumGrey: #707070;
$gray: #9b9b9b;
$inactive-grey: #979698;
$red: #f4787d;
$beige: #fbf6ec;
$darkBeige: #dbceb6;
$absoluteBlue: #0646b4;
$paleBlue: #e6f5ff;
$buttonGradient: linear-gradient(180deg, #133275 0%, #1fb3d0 100%);
$insRed: #db5461;
$userIdBackground: rgba(6, 70, 180, 0.05);
$darkBrown: #8b6825;
$purple: #9c90ec;
$white: #ffffff;
$black: #000000;
$lightWhite: #f5f5fa;
$insFormFields: #72788a;
$insBlack: #121e44;
$infoBackground: #efefef;
$createTaskInfoBackground: #fff6ea;
$primaryThemeColor: $absoluteBlue;
$violetColor: #8973eb;
$lightTabColor: rgba(0, 178, 208, 0.1);
$borderGreyColor: #cecece;
$disabledColor: $lightGray;
$textRed: $red;
$successColor: #bde5bc;
$errorColor: #fcdae4;
$appGreen: #71d1aa;
$appOrange: #fda429;
$facetBackground: #f4feff;
$lightBlueGreen: #f4fcfe;
$darkGreen: #4eb189;
$badgeRed: $red;
$hoverTextColor: #286090;
$lightBlue: #ebf1fb;
$buttonLightBlue: #88a1d5;
$hoverColor: #e6edf3;
$defaultBtnHoverColor: $insBlack;
$darkGray: #4a4a4a;
$orangeColor: #e68000;
$blueBorderColor: #4a90e2;
$oceanBlue: #0c6ef8;
$preLoginImageBackground: #caeaf6;
$activeColor: #bcd0e2;
$mrnCodeBorderColor: #b5d4dc;
$mrnCodeBackgroundColor: #edfbff;
$mrnCodeTextColor: #44727f;
$masqueradingHeaderColor: #f7debd;
$borderColor: #e0e0e0;
$inputFocusedColor: #007aff;
$providerLabelColor: #8a672c;
$lightPurple: #d0c8fc;
$emptyImageTextColor: #909895;
$bodyBackgroundColor: $lightBlue;
$infoColor: #fff6ea;
$disabledFieldBackgroundColor: #eeeeee;
$disabledFieldTextColor: #555555;
$tooltipColor: #007b92;
$painReductionColor100: #a0d5c1;
$painReductionColor90: #a9c7a9;
$painReductionColor80: #b9c590;
$painReductionColor70: #e9cf83;
$painReductionColor60: #eec368;
$painReductionColor50: #e5b466;
$painReductionColor40: #fc9c39;
$painReductionColor30: #f59746;
$painReductionColor20: #ea8c68;
$painReductionColor10: #f6806d;
$painReductionColor00: #f37980;
// Theme Colors
$themeRedColor: #d03d4d;
$themeBlueColor: #17088d;
$themeYellowColor: #ff9d1d;
$themePurpleColor: #fb647c;
$themeGrayColor: #5f5b60;
$themeLightGrayColor: #b9b9ba;
$themeOrangeColor: #ee7066;
$platinumBorder: #e6e6e6;
