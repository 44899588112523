@import 'assets/stylesheets/variables.scss';
.checkbox-container {
  position: relative;
  margin-top: 5px;
  .icon.icon-font-a-check-box:before,
  .icon.icon-font-a-check-mark:before {
    color: $primaryThemeColor;
    top: 6px;
  }
  .compliance-icon {
    position: absolute;
    top: -5px;
  }
  .check-box-label {
    margin-top: 5px;
    margin-left: 25px;
  }
}

.add-patient-modal {
  .procedure-date-info,
  .note-for-supported-keys,
  .general-cardiologist-info {
    padding: 5px 10px;
    background-color: $infoBackground;
    font-size: 12px;
    margin: 15px 0;
    color: gray;
    border-radius: 4px;
  }
  .caregiver-disclaimer-info {
    padding: 5px 10px;
    background-color: $infoBackground;
    font-size: 12px;
    margin: 15px 0;
    color: gray;
    border-radius: 4px;
    width: 85%;
    float: left;
  }
  .clear-data {
    padding: 5px 10px;
    font-size: 14px;
    margin: 15px 0;
    color: $lightGray;
    border-radius: 4px;
    width: 15%;
    float: left;
    text-align: right;
    .clear-label {
      position: relative;
      top: -4px;
    }
    .icon:before {
      font-size: 18px;
      top: 0px;
      left: -3px;
    }
    .active-icon:before {
      color: $primaryThemeColor;
    }
    .deactiver-icon:before {
      color: $lightGray;
    }
    .active-label {
      color: $primaryThemeColor;
    }
    .deactive-label {
      color: $lightGray;
    }
  }
  .add-patient-summary-title {
    font-size: 14px;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 5px;
  }
  .summary-separation {
    height: 1px;
    border-bottom: 1px solid $borderColor;
    margin: 5px 0;
  }
  .add-patient-summary-row {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 7px;
    .summary-label {
      width: 230px;
      display: block;
    }
    .summary-row-value {
      font-weight: 300;
      display: block;
    }
  }
  .margin-top-10 {
    margin-top: 10px;
  }
  .form-group {
    margin-bottom: 20px;
  }
  .summary-wrapper {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
}

.email-contactnumber-disclaimer {
  padding: 5px 10px;
  background-color: $infoBackground;
  font-size: 12px;
  margin: 15px 0;
  color: gray;
  border-radius: 4px;

  &.has-error {
    color: $textRed;
  }
}

.location-container {
  padding: 5px 15px;
  .switch {
    height: 20px !important;
  }
}

.zipcode-wrapper {
  .loading {
    background-color: #ffffff;
    background-image: url('../../assets/images/loading.gif');
    background-size: 25px 25px;
    background-position: right center;
    background-repeat: no-repeat;
  }
  .location-name {
    padding: 10px 0;
    font-weight: 100;
  }
}
.commpliance-error-msg {
  position: relative;
  top: 33px;
}
