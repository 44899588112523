@import 'assets/stylesheets/variables.scss';
.invite-code-container {
  .invite-code-action-button {
    margin-top: 20px;
    float: right;
  }
  .invite-code-header {
    font-weight: 300;
    @media screen and (min-width: 768px) {
      margin-bottom: 15px;
    }
  }

  .form-group {
    margin-bottom: 0px;
  }
}
