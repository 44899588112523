@import 'assets/stylesheets/variables.scss';
.single-select,
.multi-select {
  &__placeholder,
  &__single-value {
    font-weight: 400;
  }

  &__menu {
    margin: 4px 0;
  }

  &__option {
    padding: 6px 10px !important;
  }

  &__placeholder,
  &__single-value {
    font-weight: 300;
  }
}

.add-manager-modal {
  .form-group {
    .clr {
      .css-1lcwtbn-multiValue {
        border: 1px solid $absoluteBlue;
      }
    }
  }
}

.select-clear-icon {
  &.icon::before {
    font-size: 16px;
    position: relative;
    top: 2px;
    right: 6px;
    color: $primaryThemeColor;
    cursor: pointer;
  }
}

.multi-select__multi-value__remove {
  path {
    color: $absoluteBlue;
  }
  &:hover,
  &:focus {
    background-color: transparent !important;
    color: $primaryThemeColor !important;
  }
}

.multi-select__multi-value__label {
  color: $absoluteBlue !important;
}

.multi-select {
  &__option {
    display: flex !important;
    align-items: center !important;
    input.option--selector {
      width: auto;
      height: auto;
      margin: 0;
      padding: 0;
    }

    // label {
    //   flex: 1;
    //   margin: 0;
    //   padding: 0;
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    //   white-space: nowrap;
    // }
    & > input[type='checkbox'] {
      // width:20px !important;
      // height:20px !important;
      // display: flex !important;
    }

    & > label {
      // display: flex !important;
      margin-left: 5px !important;
      padding-top: 0 !important;
    }
  }
  &--is-disabled {
    .multi-select__multi-value {
      border-color: $disabledFieldTextColor !important;
      &__label {
        color: $disabledFieldTextColor !important;
      }
    }
  }
}

.single-select__menu-list {
  font-weight: 300;
  .single-select__option {
    cursor: pointer;
    &:hover {
      font-weight: 400;
    }
  }
  .single-select__option.single-select__option--is-selected {
    color: #333;
    background-color: rgba(0, 126, 255, 0.04);
  }
}

.multi-select__option {
  label {
    span {
      @media screen and (min-width: 768px) {
        display: inline;
      }
      display: inline-block;
      &:first-of-type {
        &:before {
          color: $primaryThemeColor;
          font-size: 20px;
        }
        vertical-align: middle;
        margin-right: 7px;
      }
    }
  }
}

.multi-select__option--is-selected {
  background-color: white !important;
  color: black !important;
}

.without-border {
  .single-select {
    &__control {
      border-radius: 5px;
      border: none;
      border: 1px solid #cccccc;
      border-radius: 5px;
      // border-bottom: 1px solid $lightGray;
      box-shadow: none;
      &:hover,
      &:focus {
        border: 1px solid $primaryThemeColor;
      }
    }

    &__indicator {
      color: $primaryThemeColor;
      padding: 5px 5px 5px 5px;
      &:hover,
      &:focus {
        color: $inputFocusedColor;
      }
    }
    &__indicator-separator {
      display: none;
    }

    &__value-container {
      padding: 0 3px;
    }
  }
}

.value-container--value-outer {
  display: flex;
  flex: 1;
  overflow: hidden;
  font-size: 15px;
  font-weight: 300;
  // color: white;
  color: #121e44;

  .value-container--value-label {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.multi-select__control {
  // border-color: $primaryThemeColor !important;
  border: 1px solid #cccccc !important;
  border-radius: 5px;
  .multi-select__dropdown-indicator {
    color: $primaryThemeColor;
    &__indicator-separator {
      display: none;
    }
  }
}
