@import 'assets/stylesheets/variables.scss';

.data-table-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100%;
  width: 100%;
  .table-wrapper {
    width: 100%;
    .table-header {
      height: 50px;
      background-color: #f9fafb;
      th {
        padding: 15px 20px;
      }
    }
    .table-body {
      .table-row {
        border-bottom: 2px solid $lightGray;
        height: 64px;
        td {
          padding: 17px;
        }
      }
      .highlight {
        background-color: #e0e0e0;
      }
      .table-full-name {
        text-decoration-line: underline;
      }
    }
  }
  .pagination-wrapper {
    padding: 20px;
  }
}
