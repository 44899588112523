@import 'assets/stylesheets/variables.scss';

.empty-container {
  padding: 100px;

  .icon:before {
    font-size: 100px;
  }

  .img {
    text-align: center;
  }

  .text {
    text-align: center;
    margin-top: 10px;
    color: $emptyImageTextColor;
    white-space: pre;
  }
}
