@import 'assets/stylesheets/variables.scss';

@import './../../../assets/stylesheets/variables.scss';

.collapsing-sidbar-wrapper {
  height: 100vh;
  .bar {
    height: 100%;
    background-image: $buttonGradient;
    transition: width 0.5s;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .bar-open {
    width: 236px;
  }
  .bar-close {
    width: 90px;
  }

  .menu-items {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    align-items: center;
    justify-content: center;
    flex: 0.499 1;
    .item {
      color: $white;
      display: flex;
      column-gap: 4px;
      padding: 8px 16px;
      .text {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
      .icon {
      }
    }
    .active {
      background-color: $white;
      color: $black;
      width: fit-content;
      border-radius: 40px;
      padding: 10px;
    }
  }
  .back-button {
    color: $white;
    flex: 0.002;
    align-self: center;
    justify-self: center;
    .icon::before {
      font-size: 24px;
    }
  }
  .logout-icon {
    color: $white;
    align-self: center;
    justify-self: center;
    padding-bottom: 40px;
  }
  .logo {
    padding: 32px 16px 0px 16px;
  }

  .logout-section {
    color: $white;
    display: flex;
    column-gap: 4px;
    padding: 8px 16px;
    .logout-icon {
    }
    .logout-text {
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
}
