@import 'assets/stylesheets/variables.scss';
.passive-mode-info-containter-otp {
  .welcome-header {
    background: #eff2fc;
    padding: 15px;
    font-weight: 100;
    font-size: 16px;
  }
  input {
    width: 30%;
    margin: auto;
    font-size: 20px !important;
  }
  .input-label-without-value,
  .input-label-with-value {
    position: relative;
  }
  .input-label-without-value {
    top: 20px;
  }
  .input-label-with-value {
    top: 5px;
  }
  .text-info {
    font-size: 18px;
    font-weight: 300;
    color: #000;
    @media screen and (max-width: 575px) {
      font-size: 18px;
    }
    @media screen and (min-width: 576px) and (max-width: 767px) {
      font-size: 18px;
    }
  }
  .text-info-small {
    font-size: 12px;
    font-weight: 300;
    color: #000;
    margin: 5px 5px 5px 5px;
    @media screen and (max-width: 575px) {
      font-size: 12px;
    }
    @media screen and (min-width: 576px) and (max-width: 767px) {
      font-size: 12px;
    }

    .link {
      color: $primaryThemeColor;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
    }
    .not-active {
      color: darkgray !important;
      pointer-events: none;
      cursor: default;
      text-decoration: none;
    }
  }
  .padding-20 {
    padding: 20px;
  }

  .btn-primary {
    font-size: 20px;
    width: 170px;
    height: 40px;
    margin: 10px;
  }

  .term-and-condition-container {
    margin: 20px;
  }

  .error-for-term {
    display: block;
    clear: both;
    font-size: 12px;
    font-weight: 300;
    margin-left: 35px;
    color: $red;
  }
}
