@import 'assets/stylesheets/variables.scss';
.section-container {
  margin-top: -30px;
  height: 60px;
  @media screen and (max-width: 770px) {
    margin-top: -13px;
  }
  .procedure-header {
    display: flex;
    justify-content: space-between;
    position: relative;
    flex-wrap: wrap;
    top: 18px;
    bottom: 21px;
  }
  .stage-value {
    color: $primaryThemeColor;
  }
}
.header-key {
  position: relative;
  width: 10vh;
  height: 20px;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
.stage-header {
  position: relative;
  // top:5px;
}
.header-value {
  position: relative;
  width: auto;
  max-width: 167px;
  height: 20px;
  font-size: 16px;
  line-height: 20px;
  color: $primaryThemeColor;
}
.current-stage-header-value {
  top: 5px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.site-header-value {
  position: relative;
  top: 5px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ct-center-header-value {
  position: relative;
  top: 5px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
