@import 'assets/stylesheets/variables.scss';
.search-result-row {
  margin: 10px 0 10px 5px;
  font-weight: 300;
  border-bottom: 1px solid $lightGray;
  // &:last-child {
  //   border-bottom: none;
  // }
  .text {
    margin: 10px 0;
  }
  .category {
    margin: 10px 0;
    padding: 5px;
    color: $gray;
    // border: 1px solid $lightGray;
    border-radius: 5px;
    font-size: 12px;
  }
  .procedureName {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 12px;
    color: $primaryThemeColor;
  }

  .chevron-class {
    &.icon:before {
      font-size: 18px;
      top: 30px;
      font-weight: 700;
      //right: 10px;
    }
  }
}
