@import 'assets/stylesheets/variables.scss';
.border-type-password {
  width: -100px;
  padding: 0px 0px 0px 0px;
  margin-bottom: 0%;
  border-radius: 5px;
  border: 2px solid $primaryThemeColor;

  input[type='text'] {
    border: 0px;
    outline: none;
  }
  @media screen and (max-width: 570px) {
    width: 90%;
    margin: 20px;
  }
}
.label_style_password {
  color: $primaryThemeColor;
  font-size: 12px;
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  margin-left: 4px;
  @media screen and (max-width: 570px) {
    display: flex;
    justify-content: left;
  }
}

.input-text-password {
  font-family: 'ProximaNova';
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 3px;
}

.input-control_before_password {
  height: 20px;
  border-color: transparent;
  align-self: center;
  align-items: center;
  font-size: auto;
  color: #72788a;
  @media screen and (max-width: 570px) {
    margin-top: 1px;
    width: 90%;
    left: 29px;
    margin-left: 10px;
  }
}

.input_control_after_password {
  align-items: center;
  height: 40px;
  font-size: auto;
  color: #72788a;
  @media screen and (max-width: 570px) {
    margin-top: 1px;
    width: 90%;
    left: 20px;
    margin-left: 3px;
  }
}

.password-show-login-page {
  position: relative;
  /* right: -4px; */
  top: -32px;
  color: $primaryThemeColor;
  cursor: pointer;
  left: 297px;
  @media screen and (max-width: 570px) {
    left: 139px;
  }
  @media only screen and (min-width: 568px) and (max-width: 1015px) {
    /* For general iPad layouts */
    left: 147px;
  }
  &.disabled {
    color: #72788a;
    cursor: default;
  }
}
