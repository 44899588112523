@import 'assets/stylesheets/variables.scss';

.input-login {
  position: 'relative';
  top: 8px;
  margin-top: -4px;
}

input::placeholder {
  opacity: 1;
  font-weight: 400;
  font-size: 16.3462px;
  position: relative;
  left: 2px;
}

.label_style {
  margin-bottom: -4px;
  position: relative;
  left: 57px;
  top: -6px;
  color: $primaryThemeColor;
  font-size: 12px;
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  margin-left: 4px;
}

.input-border {
  position: relative;
  left: 60px;
  padding: 6px;
  width: 288.46px;
  height: 48.08px;
  border: 1px solid #72788a;
  border-radius: 3.84615px;
}

.input-border-blue {
  padding: 3px;
  border: 1px solid $primaryThemeColor;
  border-radius: 3.84615px;
}

.input-container {
  margin-top: 10px;
  @media screen and (max-width: 572px) {
    margin-top: 30px;
  }

  .input-label-with-value {
    position: absolute;
    color: $gray !important;
    top: -13px;
    font-weight: 100;
    font-size: 12px;
    @media screen and (max-width: 572px) {
      top: -11px;
    }
  }
  .input-label-without-value {
    position: absolute;
    font-size: 16px;
    font-weight: 100;
    top: 5px;
    color: $gray;
    @media screen and (max-width: 572px) {
      font-size: 14px;
    }
  }
  input {
    height: 30px;
    color: black;
    border: none;
    border-radius: 0;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 5px;
    box-shadow: none !important;
    padding-left: 0px;

    @media screen and (max-width: 572px) {
      font-size: 14px;
    }

    &:focus {
      border-bottom-color: $inputFocusedColor;
    }
  }
  .input-label-is-focused {
    color: $inputFocusedColor !important;

    &.input-label-without-value {
      color: $gray !important;
    }
  }
}
