@import 'assets/stylesheets/variables.scss';

.user-id-container {
  border: 1px solid $primaryThemeColor;
  border-radius: 4px;
  display: inline-block;
  background: $userIdBackground;
  line-height: 25px;
  font-weight: bold;
  font-size: 14px;
  color: $primaryThemeColor;

  @media screen and (max-width: 572px) {
    font-size: 12px;
  }

  .id-label {
    float: left;
    text-align: center;
    border-right: 1px solid $primaryThemeColor;
    padding: 0 10px;
  }

  .id-value {
    float: left;
    text-align: center;
    padding: 0 12px;
  }
}

.user-mrn-container {
  border: 1px solid $mrnCodeBorderColor;
  border-radius: 4px;
  display: inline-block;
  background: $mrnCodeBackgroundColor;
  line-height: 25px;
  font-weight: 300;
  font-size: 14px;
  color: $mrnCodeTextColor;

  @media screen and (max-width: 572px) {
    font-size: 12px;
  }

  .mrn-label {
    float: left;
    text-align: center;
    border-right: 1px solid $mrnCodeBorderColor;
    padding: 0 10px;
  }

  .mrn-value {
    float: left;
    text-align: center;
    padding: 0 12px;
  }
}
